//hooks
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
//JSX.ELEMENTS
import Header from "../components/Header";
import Footer from "../components/Footer";
//.mui
import {
    Grid, Box, Typography,
    TableContainer, Table, TableHead,
    TableRow, TableBody, TableCell,
    CircularProgress, Tooltip, IconButton,
} from '@mui/material';
//icons
import CreateFolder from '../components/modalbox/CreateFolder';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//firebase
import { auth, db } from '../assets/utils/firebase';
import { collection, deleteDoc, doc, increment, onSnapshot, orderBy, query, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
const classes = {
    muiRoot: {
        backgroundColor: (theme) => theme.palette.background.default,
        maxWidth: '100vw',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    muiBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    muiBottomComponents: {
        width: { lg: '60%', xs: '100%' },
        minHeight: '50vh',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
    },
    muiTableHeader: {
        fontFamily: 'Varela Round',
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    muiText: {
        fontFamily: 'Varela Round',
    },
    muiTableRow: {
        backgroundColor: (theme) => theme.palette.action.hover,
    }
}
export default function Folders() {
    const navigate = useNavigate();
    const [FolderInfo, setFolderInfo] = useState([])
    let count = 1;
    const handleClick = ({ id }) => {
        navigate(`/folders/${id}/decks`)
    }
    const handleRemoveFields = ({ id }) => {
        const user = auth.currentUser
        const userFolders = collection(db, 'users', user.uid, 'userFolder')
        const userSets = collection(db, 'users', user.uid, 'userFolder', id, 'userDecks')
        onSnapshot(userSets, (snapshot) => {
            updateDoc(doc(db, 'users', user.uid), {
                totalSets: increment(-snapshot.size)
            })
        })
        deleteDoc(doc(userFolders, id));
    }
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        const cleanUp = onAuthStateChanged(auth, (user) => {
            const userFolders = query(collection(db, 'users', user.uid, 'userFolder'), orderBy('createdAt'))
            onSnapshot(userFolders, (snapshot) => {
                setFolderInfo(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            })
        })
        setIsLoading(false)
        return () => cleanUp()
    }, [])
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', width: '100vw', backgroundColor: (theme) => theme.palette.background.default, }}>
                <CircularProgress size='30vw' thickness={3} />
            </Box>
        );
    }
    return (
        <Grid sx={classes.muiRoot}>
            <Header />
            <Box sx={classes.muiBox}>
                <CreateFolder />
                <Box sx={classes.muiBottomComponents}>
                    <TableContainer sx={{ display: 'flex', width: { lg: '95%', xs: '96%' } }}>
                        <Table sx={{ minWidth: '40vw', borderCollapse: 'separate', borderSpacing: '0 12px' }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ 'th': { border: 0 }, fontSize: 15 }} >
                                    <TableCell align="center" sx={classes.muiTableHeader} >Folder ID</TableCell>
                                    <TableCell align="center" sx={classes.muiTableHeader}>Folder Name</TableCell>
                                    <TableCell align="center" sx={classes.muiTableHeader}>No. of Deck</TableCell>
                                    <TableCell align="center" sx={classes.muiTableHeader}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={classes.muiTableRow} >
                                {FolderInfo.map(({ id, category, setCount }) => (
                                    <TableRow
                                        key={id}
                                        sx={{
                                            cursor: 'pointer',
                                            'th,td': { border: 0 },
                                            'td:first-of-type': { borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' },
                                            'td:last-of-type': { borderTopRightRadius: '10px', borderBottomRightRadius: '10px' },
                                            '&:hover': {
                                                backgroundColor: (theme) => theme.palette.action.selected,
                                            }
                                        }}>
                                        <TableCell
                                            align="center"
                                            scope="row"
                                            sx={classes.muiText}
                                            onClick={() => handleClick({ id })}
                                        >{count++}
                                        </TableCell>

                                        <TableCell align="center" onClick={() => handleClick({ id })}>
                                            <Typography sx={classes.muiText} >{category}</Typography>
                                        </TableCell>

                                        <TableCell align="center" onClick={() => handleClick({ id })}>
                                            <Typography sx={classes.muiText} >{setCount}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={classes.muiText} >
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={() => handleRemoveFields({ id })}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer >
                </Box>
            </Box>
            <Footer />
        </Grid>
    )
}
