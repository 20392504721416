import * as actionTypes from '../types';
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, OAuthProvider, FacebookAuthProvider, getAdditionalUserInfo } from "firebase/auth";
import { auth, db } from '../../assets/utils/firebase';
import { setDoc, doc, onSnapshot } from 'firebase/firestore';

export const getUserInfo = () => async (dispatch) => {
    onAuthStateChanged(auth, (user = auth.currentUser) => {
        const userRef = doc(db, 'users', user.uid)
        onSnapshot(userRef, (doc) => {
            const data = ({ ...doc.data(doc.id) })
            localStorage.setItem('userInfo', JSON.stringify(data))
            localStorage.setItem('isAuth', true)
            dispatch({ type: actionTypes.LOGGED_IN_STATE, payload: data })
        })
    })
};
export const createNewUserEmail = (email, password, firstName, lastName, birthDate, gender, photoURL) => async (dispatch) => {
    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            onAuthStateChanged(auth, (user) => {
                if (getAdditionalUserInfo(userCredential).isNewUser) {
                    const userRef = doc(db, 'users', user.uid)
                    setDoc(userRef, {
                        id: user.uid,
                        firstName: firstName,
                        lastName: lastName,
                        displayName: firstName + " " + lastName,
                        email: email,
                        photoURL: photoURL,
                        birthDate: birthDate,
                        gender: gender,
                        totalSets: 0,
                        totalPosts: 0,
                        totalGroups: 0,
                    }).then(() => {
                        onSnapshot(userRef, (doc) => {
                            const data = ({ ...doc.data(doc.id) })
                            localStorage.setItem('userInfo', JSON.stringify(data))
                            localStorage.setItem('isAuth', true)
                            dispatch({ type: actionTypes.LOGGED_IN_STATE, payload: data })
                        })
                    })
                }
                else {
                    const userRef = doc(db, 'users', user.uid)
                    onSnapshot(userRef, (doc) => {
                        setDoc(userRef, {
                            id: user.uid,
                            firstName: doc.data().firstName,
                            lastName: doc.data().lastName,
                            displayName: user.displayName,
                            email: user.email,
                            photoURL: user.photoURL,
                            birthDate: doc.data().birthDate,
                            gender: doc.data().gender,
                            totalSets: doc.data().totalSets,
                            totalPosts: doc.data().totalPosts,
                            totalGroups: doc.data().totalGroups,
                        })
                        const data = ({ ...doc.data(doc.id) })
                        localStorage.setItem('userInfo', JSON.stringify(data))
                        localStorage.setItem('isAuth', true)
                        dispatch({ type: actionTypes.LOGGED_IN_STATE, payload: data })
                    })
                }

            })
        })
        .catch(() => {
            dispatch({ type: actionTypes.ON_ERROR, payload: "Invalid login credentials" })
        });
}
const FBprovider = new FacebookAuthProvider();
export const setLoggedInUserFB = () => async (dispatch) => {
    signInWithPopup(auth, FBprovider)
        .then((result) => {
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            onAuthStateChanged(auth, (user = result.user) => {
                if (getAdditionalUserInfo(result).isNewUser) {
                    const userRef = doc(db, 'users', user.uid);
                    const fullName = user.displayName;
                    const parts = fullName.split(' ')
                    setDoc(userRef, {
                        id: user.uid,
                        firstName: parts[0],
                        lastName: parts[1],
                        displayName: user.displayName,
                        email: user.email,
                        photoURL: user.photoURL + `?height=500&access_token=${accessToken}`,
                        birthDate: '',
                        gender: '',
                        totalSets: 0,
                        totalPosts: 0,
                        totalGroups: 0,
                    }).then(() => {
                        onSnapshot(userRef, (doc) => {
                            const data = ({ ...doc.data(doc.id) })
                            localStorage.setItem('userInfo', JSON.stringify(data))
                            localStorage.setItem('isAuth', true)
                            dispatch({ type: actionTypes.LOGGED_IN_STATE, payload: data })
                        })
                    })
                }
                else {
                    const userRef = doc(db, 'users', user.uid)
                    onSnapshot(userRef, (doc) => {
                        setDoc(userRef, {
                            id: user.uid,
                            firstName: doc.data().firstName,
                            lastName: doc.data().lastName,
                            displayName: user.displayName,
                            email: user.email,
                            photoURL: user.photoURL + `?height=500&access_token=${accessToken}`,
                            birthDate: doc.data().birthDate,
                            gender: doc.data().gender,
                            totalSets: doc.data().totalSets,
                            totalPosts: doc.data().totalPosts,
                            totalGroups: doc.data().totalGroups,
                        })
                        const data = ({ ...doc.data(doc.id) })
                        localStorage.setItem('userInfo', JSON.stringify(data))
                        localStorage.setItem('isAuth', true)
                        dispatch({ type: actionTypes.LOGGED_IN_STATE, payload: data })
                    })
                }
            })
        }).catch((error) => {
            dispatch({ type: actionTypes.ON_ERROR, payload: error })
        });
}
const googleProvider = new GoogleAuthProvider();
export const setLoggedInUserGoogle = () => async (dispatch) => {
    signInWithPopup(auth, googleProvider)
        .then((result) => {
            onAuthStateChanged(auth, (user = result.user) => {
                if (getAdditionalUserInfo(result).isNewUser) {
                    const userRef = doc(db, 'users', user.uid)
                    const fullName = user.displayName;
                    const parts = fullName.split(' ')
                    setDoc(userRef, {
                        id: user.uid,
                        firstName: parts[0],
                        lastName: parts[1],
                        displayName: user.displayName,
                        email: user.email,
                        photoURL: user.photoURL,
                        birthDate: '',
                        gender: '',
                        totalSets: 0,
                        totalPosts: 0,
                        totalGroups: 0,
                    }).then(() => {
                        onSnapshot(userRef, (doc) => {
                            const data = ({ ...doc.data(doc.id) })
                            localStorage.setItem('userInfo', JSON.stringify(data))
                            localStorage.setItem('isAuth', true)
                            dispatch({ type: actionTypes.LOGGED_IN_STATE, payload: data })
                        })
                    })
                }
                {
                    const userRef = doc(db, 'users', user.uid)
                    onSnapshot(userRef, (doc) => {
                        setDoc(userRef, {
                            id: user.uid,
                            firstName: doc.data().firstName,
                            lastName: doc.data().lastName,
                            displayName: user.displayName,
                            email: user.email,
                            photoURL: user.photoURL,
                            birthDate: doc.data().birthDate,
                            gender: doc.data().gender,
                            totalSets: doc.data().totalSets,
                            totalPosts: doc.data().totalPosts,
                            totalGroups: doc.data().totalGroups,
                        })
                        const data = ({ ...doc.data(doc.id) })
                        localStorage.setItem('userInfo', JSON.stringify(data))
                        localStorage.setItem('isAuth', true)
                        dispatch({ type: actionTypes.LOGGED_IN_STATE, payload: data })
                    })
                }
            })
        }).catch((error) => {
            dispatch({ type: actionTypes.ON_ERROR, payload: error })
        });
}
const MSprovider = new OAuthProvider('microsoft.com');
export const setLoggedInUserMicrosoft = () => async (dispatch) => {
    signInWithPopup(auth, MSprovider)
        .then((result) => {
            onAuthStateChanged(auth, (user = result.user) => {
                if (getAdditionalUserInfo(result).isNewUser) {
                    const userRef = doc(db, 'users', user.uid)
                    const fullName = user.displayName;
                    const parts = fullName.split(' ')
                    setDoc(userRef, {
                        id: user.uid,
                        firstName: parts[0],
                        lastName: parts[1],
                        displayName: user.displayName,
                        email: user.email,
                        photoURL: '',
                        birthDate: '',
                        gender: '',
                        totalSets: 0,
                        totalPosts: 0,
                        totalGroups: 0,
                    }).then(() => {
                        onSnapshot(userRef, (doc) => {
                            const data = ({ ...doc.data(doc.id) })
                            localStorage.setItem('userInfo', JSON.stringify(data))
                            localStorage.setItem('isAuth', true)
                            dispatch({ type: actionTypes.LOGGED_IN_STATE, payload: data })
                        })
                    })
                } else {
                    const userRef = doc(db, 'users', user.uid)
                    onSnapshot(userRef, (doc) => {
                        setDoc(userRef, {
                            id: user.uid,
                            firstName: doc.data().firstName,
                            lastName: doc.data().lastName,
                            displayName: user.displayName,
                            email: user.email,
                            photoURL: doc.data().photoURL,
                            birthDate: doc.data().birthDate,
                            gender: doc.data().gender,
                            totalSets: doc.data().totalSets,
                            totalPosts: doc.data().totalPosts,
                            totalGroups: doc.data().totalGroups,
                        })
                        const data = ({ ...doc.data(doc.id) })
                        localStorage.setItem('userInfo', JSON.stringify(data))
                        localStorage.setItem('isAuth', true)
                        dispatch({ type: actionTypes.LOGGED_IN_STATE, payload: data })
                    })
                }
            })
        })
        .catch((error) => {
            // Handle error.
        });
}
export const setLoggedInUserEmail = (email, password) => async (dispatch) => {
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            const userRef = doc(db, 'users', user.uid)
            onSnapshot(userRef, (doc) => {
                const data = ({ ...doc.data(doc.id) })
                localStorage.setItem('userInfo', JSON.stringify(data))
                localStorage.setItem('isAuth', true)
                dispatch({ type: actionTypes.LOGGED_IN_STATE, payload: data })
            })
            // ...
        })
        .catch(() => {
            dispatch({ type: actionTypes.ON_ERROR, payload: "Invalid login credentials" })
        });
}

export const setLoggedOutState = () => async (dispatch) => {
    try {
        auth.signOut().then(() => {
            localStorage.clear()
            window.location.reload(true);
            dispatch({ type: actionTypes.LOGGED_OUT_STATE, payload: null });
        }).catch((error) => {
            // An error happened.
            dispatch({ type: actionTypes.ON_ERROR, payload: error })
        });
    } catch (error) {
        dispatch({ type: actionTypes.ON_ERROR, payload: error })
    }
}
