//hooks
import React, { useState, useEffect } from 'react'
import { motion } from "framer-motion";
import { useNavigate, useParams } from 'react-router-dom';
//JSX.ELEMENTS
import Header from '../components/Header';
//.mui
import { styled, alpha } from '@mui/material/styles';
import {
    Card, IconButton, Box, Button,
    FormControl, InputLabel, Select, MenuItem,
    InputBase, Typography, Grid, Tooltip,
    Snackbar, CircularProgress
} from '@mui/material'
//icons
import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit';
import StyleIcon from '@mui/icons-material/Style';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import RateReviewIcon from '@mui/icons-material/RateReview';
import PlayCircle from '@mui/icons-material/PlayCircle';
//firebase
import { auth, db } from '../assets/utils/firebase';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    increment,
    onSnapshot,
    orderBy,
    query,
    Timestamp,
    updateDoc
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.15) : alpha(theme.palette.common.black, 0.15),
    marginLeft: 0,
    width: 'auto',
    borderRadius: `25px 25px 25px 25px`,
    [theme.breakpoints.down('sm')]: {
        width: '50%',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'text.primary',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));

const classes = {
    muiRoot: {
        backgroundColor: (theme) => theme.palette.background.default,
        maxWidth: '100vw',
        minHeight: '100vh',
    },
    muiBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '5vh'
    },
    muiHelpers: {
        width: { lg: '60%', xs: '96%' },
        minHeight: '10vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    muiCard: {
        width: { lg: '22vw', xs: '85vw' },
        height: { lg: 160, xs: 150 },
        display: 'flex',
        flexDirection: 'column',
    },
    muiButton: {
        width: { lg: '50%', xs: '90%' },
        border: '2px dashed',
        borderColor: (theme) => theme.palette.secondary.main,
        minHeight: '10vh',
        textTransform: 'none',
        marginTop: 2
    },
    muiButtonLabel: {
        fontSize: '2rem',
        verticalAlign: 'middle',
        fontFamily: 'Varela Round'
    },
}
export default function Decks() {
    const navigate = useNavigate();
    const currentUserURL = useSelector(state => state.user)
    const { folderId } = useParams();
    const [folderTitle, setFolderTitle] = useState([])
    const [folderDesc, setFolderDesc] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [folderContents, setFolderContents] = useState([])
    const [postData, setPostData] = useState([]);
    const [filter, setFilter] = useState('Recent');
    //snackBar
    const [open, setOpen] = useState(false);
    //handlers
    const handleFilter = (event) => {
        setFilter(event.target.value);
    };
    const handleReview = ({ id }) => {
        navigate(`/folders/${folderId}/decks/review/${id}`)
    };
    const handlePlay = ({ id }) => {
        navigate(`/folders/${folderId}/decks/test/${id}`)
    };
    const handleRemoveFields = ({ id }) => {
        const user = auth.currentUser
        const folderRef = doc(db, 'users', user.uid, 'userFolder', folderId);
        const userSets = collection(db, 'users', user.uid, 'userFolder', folderId, 'userDecks')
        updateDoc(folderRef, {
            setCount: increment(-1)
        })
        updateDoc(doc(db, 'users', user.uid), {
            totalSets: increment(-1)
        })
        deleteDoc(doc(userSets, id))
    }
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleShare = ({ id, title, cardCount, yearLevel }) => {
        const user = auth.currentUser;
        const cardsRef = collection(db, 'users', user.uid, 'userFolder', folderId, 'userDecks', id, 'userCards');
        const postRef = collection(db, 'posts');
        onSnapshot(cardsRef, (snapshot) => {
            let myCards = [];
            snapshot.forEach((doc) => {
                myCards.push({
                    id: doc.id,
                    term: doc.get('term'),
                    definition: doc.get('definition'),
                })
            })
            setPostData(myCards)
        })
        addDoc(postRef, {
            userID: user.uid,
            displayName: currentUserURL.currentUser.displayName,
            photoURL: currentUserURL.currentUser.photoURL,
            createdAt: moment(Timestamp.now().toDate()).fromNow(),
            title: title,
            yearLevel: yearLevel,
            cardCount: cardCount,
        }).then((docRef) => {
            postData.forEach((data) => {
                addDoc(collection(docRef, 'postCards'), {
                    id: data.id,
                    term: data.term,
                    definition: data.definition,
                })
            })
        })
    }
    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={() => { navigator.clipboard.writeText(''); }}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    useEffect(() => {
        const cleanup = onAuthStateChanged(auth, (user) => {
            const setsRef = query(collection(db, 'users', user.uid, 'userFolder', folderId, 'userDecks'), orderBy('createdAt'));
            onSnapshot(setsRef, (snapshot) => {
                setFolderContents(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            })
            const folderRef = doc(db, 'users', user.uid, 'userFolder', folderId);
            getDoc(folderRef).then((docSnap) => {
                setFolderTitle(docSnap.get('category'))
                setFolderDesc(docSnap.get('description'))
            })
        })
        setTimeout(() => setIsLoading(false), 1000)
        return () => cleanup()
    }, [folderId])
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', width: '100vw', backgroundColor: (theme) => theme.palette.background.default, }}>
                <CircularProgress size='30vw' thickness={3} />
            </Box>
        );
    }
    return (
        <Box sx={classes.muiRoot}>
            <Header />
            <Box sx={classes.muiBox}>
                <Box component={Button}
                    sx={classes.muiButton}
                    onClick={() => { navigate(`/folders/${folderId}/decks/create`) }}>
                    <Typography sx={classes.muiButtonLabel} color='secondary'>Create New Deck</Typography>
                    <StyleIcon sx={{
                        marginLeft: 1,
                        fontSize: '3rem',
                        verticalAlign: 'middle'
                    }} color='secondary' />
                </Box>
                <Box sx={{ minWidth: { lg: '70vw', xs: '90vw' }, alignItems: 'flex-start', margin: 1 }}>
                    <Typography sx={{ fontSize: '1.4rem', fontFamily: 'Varela Round' }} color='text.primary'>Folder Name: {folderTitle}</Typography>
                    <Typography sx={{ fontSize: '1.1rem', fontFamily: 'Varela Round' }} color='text.disabled'>Description: {folderDesc}</Typography>
                </Box>
                <Box sx={classes.muiHelpers}>
                    <FormControl sx={{ minWidth: 120 }} size='small' >
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filter}
                            label="Filter"
                            onChange={handleFilter}
                        >
                            <MenuItem value={'Recent'}>Recent</MenuItem>
                            <MenuItem value={'Created'}>Created</MenuItem>
                            <MenuItem value={'Studied'}>Studied</MenuItem>
                        </Select>
                    </FormControl>
                    <Box component='span' sx={{ flexGrow: 1 }} />
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon sx={{ color: 'text.primary' }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                </Box>
                <Grid container spacing={{ lg: 2, xs: 2 }} sx={{
                    display: 'flex',
                    width: { lg: '70%', xs: '90%' },
                    flexDirection: { lg: 'row', xs: 'column' },
                    flexWrap: 'wrap',
                }}>
                    {folderContents.map(({ id, title, cardCount, yearLevel }) => (
                        <Grid item lg={4} key={id}>
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                            >
                                <Card sx={classes.muiCard} elevation={5}>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: 'text.disabled', padding: '0px 10px 0px 10px', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '1.4rem', fontFamily: 'Varela Round', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Title: {title}</Typography>
                                        <Box component='span' sx={{ flexGrow: 1 }} />
                                        <Tooltip title="Review">
                                            <IconButton onClick={() => handleReview({ id })}>
                                                <RateReviewIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Play">
                                            <IconButton onClick={() => handlePlay({ id })}>
                                                <PlayCircle fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                            <IconButton size='medium' onClick={() => { navigate(`/folders/${folderId}/decks/edit/${id}`) }}>
                                                <EditIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton onClick={() => handleRemoveFields({ id })} >
                                                <DeleteForeverIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1, paddingTop: 1 }}>
                                        <Typography sx={{ fontSize: '1.1rem', fontFamily: 'Varela Round' }} color='text.secondary'>Level: {yearLevel}</Typography>
                                        <Typography sx={{ fontSize: '.8rem', fontFamily: 'Varela Round' }} color='text.disabled'>{cardCount} Cards</Typography>
                                    </Box>
                                    <Box component='span' sx={{ flexGrow: 1 }} />

                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', margin: '0px 5px 10px 10px', zIndex: 1 }}>
                                        <Button color='inherit' sx={{ textTransform: 'none', fontSize: '1rem', fontFamily: 'Varela Round' }} onClick={() => handleShare({ id, title, cardCount, yearLevel })}>
                                            Share<ShareIcon fontSize='small' />
                                        </Button>
                                    </Box>
                                </Card>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Copied"
                action={action}
            />
        </Box >
    )
}
