import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import Header from "../components/Header";
import backGround from "../assets/images/try.png";
import { Grid, Box, CircularProgress, Card, CardHeader, Tooltip, IconButton, Typography, Divider } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import TagIcon from '@mui/icons-material/Tag';
import CloseIcon from '@mui/icons-material/Close';

const classes = {
    muiRoot: {
        backgroundColor: (theme) => theme.palette.background.default,
        maxWidth: '100vw',
        minHeight: '100vh',
    },
    muiBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: `url(${backGround})`,
        padding: 2,
    },
    muiContainer: {
        width: '90vw',
        height: '90vh',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'flex-start',
        border: '5px solid',
    },
    muiComponents: {
        width: '90vw',
        height: '90vh',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'flex-start',
        overFlow: 'scroll'
    },
    muiLabel: {
        fontFamily: 'Varela Round',
        fontSize: '1rem'
    },
    muiText: {
        fontFamily: 'Varela Round',
        fontSize: '.8rem',
    },
    show: { display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 1 },
    hidden: { display: 'none', alignItems: 'center', justifyContent: 'center', padding: 1 }
}

export default function Messages() {
    const [isLoading, setIsLoading] = useState(true)
    const [showGroup, setShowGroup] = useState(false)
    const [showIndi, setShowIndi] = useState(false)
    const handleShowGroup = () => {
        setShowGroup(!showGroup)
    }
    const handleShowIndi = () => {
        setShowIndi(!showIndi)
    }
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 1000)
    }, [navigate])
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', backgroundColor: (theme) => theme.palette.background.default, }}>
                <CircularProgress size='30vw' thickness={3} />
            </Box>
        );
    }
    return (
        <Grid sx={classes.muiRoot}>
            <Header />
            <Box sx={classes.muiBox}>
                <Card sx={classes.muiContainer}>
                    <CardHeader
                        sx={{ backgroundColor: '#1F7750', width: '100%', borderBottom: '5px solid' }}
                        titleTypographyProps={{ variant: 'h5' }}
                        title="Messages"
                    />
                    <Box sx={classes.muiComponents}>
                        <Box sx={{ minWidth: '15%', height: '100%', borderRight: '5px solid', display: { lg: 'block', xs: 'none' }, }}>
                            <Box component='span' sx={{ display: 'flex', alignItems: 'center', padding: 1 }} onClick={() => { navigate('/messages/chats/public') }}>
                                <TagIcon fontSize='small' />
                                <Typography sx={classes.muiLabel}>Public</Typography>
                            </Box>
                            <Divider />
                            <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '50%' }} >
                                <IconButton onClick={handleShowGroup}>
                                    {showGroup === true ?
                                        < ArrowDropDownIcon /> : <ArrowRightIcon />
                                    }
                                </IconButton>
                                <Typography sx={classes.muiLabel}>Groups</Typography>
                                <Box component='span' sx={{ flexGrow: 1 }} />
                                <Tooltip title="Add">
                                    <IconButton >
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Divider />
                            <Box sx={showGroup === true ? classes.show : classes.hidden} onClick={() => { navigate('/messages/chats/group#1') }} >
                                <Box component='span' sx={{ flexGrow: 1 }} />
                                <TagIcon fontSize='small' />
                                <Typography sx={classes.muiText}>Group Name #1</Typography>
                                <Box component='span' sx={{ flexGrow: 1 }} />
                                <Tooltip title="Delete">
                                    <IconButton >
                                        <CloseIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Divider />

                            <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '50%' }} >
                                <IconButton onClick={handleShowIndi}>
                                    {showIndi === true ?
                                        < ArrowDropDownIcon /> : <ArrowRightIcon />
                                    }
                                </IconButton>
                                <Typography sx={classes.muiLabel}>Individual</Typography>
                                <Box component='span' sx={{ flexGrow: 1 }} />
                                <Tooltip title="Add">
                                    <IconButton >
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Divider />
                            <Box sx={showIndi === true ? classes.show : classes.hidden} onClick={() => { navigate('/messages/chats/anotherUserID') }}  >
                                <Box component='span' sx={{ flexGrow: 1 }} />
                                <TagIcon fontSize='small' />
                                <Typography sx={classes.muiText}>Username #1</Typography>
                                <Box component='span' sx={{ flexGrow: 1 }} />
                                <Tooltip title="Delete">
                                    <IconButton >
                                        <CloseIcon fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Divider />
                        </Box>
                        <Outlet />
                    </Box>
                </Card>
            </Box >
        </Grid >
    )
}
