import React, { useState, useEffect } from 'react'
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Grid, Box, CircularProgress } from '@mui/material';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../assets/utils/firebase';
import PostBox from '../components/posting/PostBox';

const classes = {
    muiRoot: {
        backgroundColor: (theme) => theme.palette.background.default,
        maxWidth: '100vw',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    muiBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
    },
    muiComponents: {
        width: { lg: '60%', xs: '100%' },
        minHeight: '100vh',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    muiText: {
        fontSize: '3rem'
    },
}
export default function Home() {
    const [isLoading, setIsLoading] = useState(true)
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        const postRef = collection(db, 'posts');
        const cleanup = onSnapshot(postRef, (snapshot) => {
            setPosts(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        })
        setTimeout(() => setIsLoading(false), 1000)
        return () => cleanup()
    }, []);
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', backgroundColor: (theme) => theme.palette.background.default, }}>
                <CircularProgress size='30vw' thickness={3} />
            </Box>
        );
    }
    return (
        <Grid sx={classes.muiRoot}>
            <Header />
            <Box sx={classes.muiBox}>
                {posts.map(({ id, userID, photoURL, displayName, createdAt, title, cardCount, yearLevel }) => (
                    <PostBox
                        key={id}
                        postID={id}
                        userID={userID}
                        photoURL={photoURL}
                        displayName={displayName}
                        createdAt={createdAt}
                        title={title}
                        yearLevel={yearLevel}
                        cardCount={cardCount}
                    />
                ))}
            </Box>
            <Footer />
        </Grid>
    )
}