import React, { useState } from 'react'
import moment from 'moment';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { auth, db } from '../../assets/utils/firebase';
import { TextField, IconButton, Box, Popper, Tooltip } from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import { useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';

export default function TextInput({ scroll }) {
    const [msg, setMsg] = useState('');
    const smsRef = collection(db, 'messages');
    const onSend = (event) => {
        onAuthStateChanged(auth, (user) => {
            addDoc(smsRef, {
                text: msg,
                senderphotoURL: user.photoURL,
                senderID: user.uid,
                createdAt: moment(Timestamp.now().seconds * 1000).format('lll')
            })
        })
        setMsg('')
        scroll.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpenCodeField = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    return (
        <Box sx={{
            position: 'sticky',
            display: 'flex',
            width: '100%',
            bottom: 0,
            borderTop: '1px solid lightgray',
            paddingBottom: '15px',
            paddingTop: '15px',
            backgroundColor: '#1F7750',
            zIndex: 1
        }}>
            <Popper id={id} open={open} anchorEl={anchorEl} placement='top-start' >
                <Box sx={{ border: 1, p: 1, backgroundColor: '#1F7750', marginBottom: 3, borderRadius: 1, display: 'flex', flexDirection: 'row', minWidth: 300 }}>
                    <TextField placeholder='Enter Set Code'
                        fullWidth
                        size='small'
                        autoComplete='off' />
                    <Tooltip title='Share Deck'>
                        <IconButton >
                            <PresentToAllIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Popper>
            <IconButton onClick={handleOpenCodeField}>
                <AddCircleIcon />
            </IconButton>
            <TextField
                value={msg}
                onChange={e => setMsg(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === "Enter") { document.getElementById("mySend").click() }
                }}
                placeholder='Message...'
                fullWidth
                size='small'
                autoComplete='off'
            />
            <IconButton onClick={onSend} disabled={msg === ''} id='mySend'>
                <SendIcon />
            </IconButton>
        </Box>
    )
}
