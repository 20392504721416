import React, { useEffect, useState } from 'react';
import {
    Card, Stepper, Typography, Box,
    Tooltip, IconButton, CircularProgress,
    Divider, Avatar
} from '@mui/material';
import ReactCardFlip from 'react-card-flip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReplyIcon from '@mui/icons-material/Reply';
import { useSelector } from 'react-redux';
import { auth, db } from '../../assets/utils/firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import Header from '../Header';
import Footer from '../Footer';

const classes = {
    muiBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        minHeight: '65vh',
        flexDirection: 'column',
        marginBottom: 10,
        marginTop: 10,

    },
    muiContainer: {
        backgroundColor: (theme) => theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minWidth: { lg: '40vw', xs: '90vw' },
        minHeight: { lg: '50vh', xs: '65vh' },
        padding: { lg: 2, xs: 0 },
        margin: 1,
        boxShadow: 3,
    },
    textDefinition: {
        fontSize: { lg: '1.8rem', xs: '1.2rem' },
        fontFamily: 'Varela Round',
        alignSelf: "center",
        marginTop: 2,
    },
    muiHelpers: {
        width: { lg: '50%', xs: '96%' },
        minHeight: '5vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 2
    },
    muiCard: {
        boxShadow: 5,
        display: 'flex',
        flexDirection: 'column',
        width: { lg: '40vw', xs: '86vw' },
        height: { lg: '50vh', xs: '50vh' },
        backgroundColor: '#1F7750',
        padding: 1,
        margin: 1
    },
    withImage: { display: 'flex', width: '320px', height: '320px', alignSelf: 'center' },
    withoutImage: { display: 'none', width: '320px', height: '320px', alignSelf: 'center' },
    muiButtonWrapper: { display: 'flex', flexDirection: 'row', alignSelf: 'flex-end' },
}


export default function DisplayReview() {
    const user = useSelector(state => state.user)
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    //DATA
    const [count, setCount] = useState(1);
    const [activeStep, setActiveStep] = useState(0);
    const [maxSteps, setmaxSteps] = useState()
    const { folderId, setId } = useParams();
    const handlePageBack = () => {
        navigate(`/folders/${folderId}/decks`)
        localStorage.removeItem('myCards')
    }
    //card-Actions
    const [isFlipped, setIsFlipped] = useState(false)
    const handleClick = () => {
        setIsFlipped(!isFlipped)
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCount(count + 1);
        setIsFlipped(false);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setCount(count - 1);
        setIsFlipped(false)
    };

    useEffect(() => {
        const setup = onAuthStateChanged(auth, (user) => {
            const userCards = collection(db, 'users', user.uid, 'userFolder', folderId, 'userDecks', setId, 'userCards')
            onSnapshot(userCards, (snapshot) => {
                let myCards = [];
                snapshot.forEach((doc) => {
                    myCards.push({
                        id: doc.id,
                        term: doc.get('term'),
                        definition: doc.get('definition'),
                    })
                })
                setmaxSteps(snapshot.size)
                localStorage.setItem('myCards', JSON.stringify(myCards))
                setIsLoading(false)
            })
        })
        return () => setup()
    }, [folderId, setId])

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', width: '100vw', backgroundColor: (theme) => theme.palette.background.default, }}>
                <CircularProgress size='30vw' thickness={3} />
            </Box>
        );
    }
    var myCards = localStorage.getItem("myCards")
    var data = JSON.parse(myCards)[activeStep]
    return (
        <Box sx={{ backgroundColor: (theme) => theme.palette.background.default, }}>
            <Header />
            <Stepper activeStep={maxSteps} alternativeLabel>
                <Box sx={classes.muiBox}>
                    <Card sx={classes.muiContainer} >
                        <Box sx={{ display: 'flex', alignSelf: 'center', width: { lg: '42vw', xs: '100vw' } }}>
                            <Tooltip title="Return">
                                <IconButton size='medium' onClick={handlePageBack}>
                                    <ReplyIcon />
                                </IconButton>
                            </Tooltip>
                            <Typography></Typography>
                        </Box>
                        <Box sx={{ alignSelf: "flex-start", flexDirection: "row", flexWrap: "wrap", display: 'flex', padding: 1 }}>
                            <Avatar sx={{ position: 'static', width: 45, height: 45, border: '2px solid' }} src={user.currentUser.photoURL} />
                            <Box sx={{ flexDirection: "column", flexWrap: "wrap", display: 'flex', marginLeft: 2 }} >
                                <Typography sx={{ fontWeight: 500, }} color='text.disabled' >Created by</Typography>
                                <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>{user.currentUser.displayName}</Typography>
                            </Box>
                        </Box>
                        <ReactCardFlip
                            isFlipped={isFlipped}
                            flipDirection='vertical'
                            flipSpeedFrontToBack={0.4}
                            flipSpeedBackToFront={0.4}
                        >
                            <Card sx={classes.muiCard} onClick={handleClick}>
                                <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, fontFamily: 'Varela Round' }}>{count}</Typography>
                                <Divider style={{ width: '100%', backgroundColor: '#EC884D', height: 2 }} />
                                <Typography sx={{ fontSize: { lg: '1.8rem', xs: '1.2rem' }, fontFamily: 'Varela Round', textAlign: 'center' }} color='secondary'>
                                    Definition
                                </Typography>
                                <Divider style={{ width: '100%', marginBottom: 2, backgroundColor: '#EC884D', height: 2 }} />
                                <Typography sx={{
                                    fontSize: { lg: '1.8rem', xs: '1.5rem' },
                                    fontFamily: 'Varela Round',
                                    textAlign: 'center',
                                    marginTop: 10
                                }} color='text.primary'>
                                    {data.definition}
                                </Typography>
                            </Card>

                            <Card sx={classes.muiCard} onClick={handleClick}>
                                <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, fontFamily: 'Varela Round' }}>{count}</Typography>
                                <Divider style={{ width: '100%', backgroundColor: '#639BC8', height: 2 }} />
                                <Typography sx={{ fontSize: { lg: '1.8rem', xs: '1.2rem' }, fontFamily: 'Varela Round', textAlign: 'center' }} color='primary'>
                                    Term
                                </Typography>
                                <Divider style={{ width: '100%', marginBottom: 2, backgroundColor: '#639BC8', height: 2 }} />
                                <Typography
                                    sx={{
                                        fontSize: { lg: '1.8rem', xs: '1.5rem' },
                                        fontFamily: 'Varela Round',
                                        textAlign: 'center',
                                        marginTop: 10
                                    }} color='text.primary'>
                                    {data.term}
                                </Typography>
                            </Card>
                        </ReactCardFlip>
                        <Box sx={classes.muiHelpers}>
                            <IconButton disabled={activeStep === 0} size='medium' onClick={handleBack}>
                                <ArrowBackIcon fontSize='large' />
                            </IconButton>

                            <Box component='span' sx={{ flexGrow: .2 }} />

                            <IconButton disabled={activeStep === maxSteps - 1} size='medium' onClick={handleNext}>
                                <ArrowForwardIcon fontSize='large' />
                            </IconButton>
                        </Box>
                    </Card>

                </Box>
            </Stepper>
            <Footer />
        </Box>
    )
}
