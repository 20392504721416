//hooks
import React, { useState } from 'react'
import { motion } from "framer-motion";
import { useNavigate, useParams } from 'react-router-dom';
//JSX.ELEMENTS
import Header from '../Header';
//.mui
import {
    Card, TextField, Box, IconButton, Typography, Tooltip, Divider, Button, Alert
} from '@mui/material'
import { styled } from '@mui/material/styles';
//icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddIcon from '@mui/icons-material/Add';
import ReplyIcon from '@mui/icons-material/Reply'
const Input = styled('input')({
    display: 'none',
});
const classes = {
    muiRoot: {
        backgroundColor: (theme) => theme.palette.background.default,
        maxWidth: '100vw',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    muiBox: {
        display: 'flex',
        justifyContent: 'center',
        padding: 2
    },
    muiComponents: {
        width: { lg: '50vw', xs: '100vw' },
        minHeight: '100vh',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    muiUpperBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 2
    },
    muiLowerBox: {
        width: '100%',
        minHeight: '100vh',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
    },
    muiCard: {
        width: '100%',
        height: { lg: 180, xs: 350 },
        flexDirection: 'column',
        display: 'flex',
        margin: 2,
        justifyContent: 'center',
    },
    cookieAlert: {
        margin: 1,
        width: '80%',
        alignItems: 'center',
        "& .MuiAlert-icon": {
            fontSize: 32
        }
    }
}
export default function CreateSets() {
    const navigate = useNavigate();
    const { folderId, setId } = useParams();
    let image = null
    console.log(setId)
    const [cards, setCards] = useState([{ card: "" }, { card: "" }])
    let count = 1
    // const onCreate = () => {
    //     onAuthStateChanged(auth, (user) => {
    //         const userFolders = collection(db, 'users', user.uid, 'userFolder')
    //         if (user !== null) {
    //             addDoc(userFolders, {
    //                 category: category,
    //                 description: description,
    //                 createdAt: moment(Timestamp.now().seconds * 1000).fromNow()
    //             })
    //         }
    //     })
    //     setCategory('')
    //     setDescription('')
    //     handleClose()
    // }
    const handleCardsAdd = () => {
        setCards([...cards, { card: "" }])
    }
    const handleCardsRemove = (index) => {
        const list = [...cards];
        const id = list.indexOf(index - 1)
        list.splice(id, 1);
        setCards(list)
    }
    return (
        <Box sx={classes.muiRoot}>
            <Header />
            <Box sx={classes.muiBox}>
                <Box sx={classes.muiComponents}>
                    <Box sx={classes.muiUpperBox}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100% ', marginTop: 4, marginBottom: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Tooltip title="Return">
                                        <IconButton size='medium' onClick={() => { navigate(`/folders/${folderId}/decks`) }}>
                                            <ReplyIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography sx={{ fontSize: '2rem', fontFamily: 'Varela Round' }} color='text.primary'>Edit Deck</Typography>
                                </Box>
                                <Typography sx={{ fontSize: '1rem', fontFamily: 'Varela Round' }} color='text.disabled'>set Name</Typography>
                                <Typography sx={{ fontSize: '1rem', fontFamily: 'Varela Round' }} color='text.disabled'>Code: {setId}</Typography>
                            </Box>
                            <Box component='span' sx={{ flexGrow: 1 }} />
                            <Button disabled={cards.length <= 2} variant='contained' sx={{ maxHeight: 50 }}>SAVE</Button>
                        </Box>
                        <TextField label="Title" variant="outlined" sx={{ margin: 1, width: '90%' }} size='small' autoComplete='off' />
                        <TextField
                            sx={{ margin: 1, width: '90%' }}
                            size='small'
                            id="outlined-multiline-static"
                            label="Description"
                            autoComplete='off'
                            multiline
                            rows={2}
                        />
                        {cards.length <= 2 ?
                            <Alert severity="info" sx={classes.cookieAlert}>
                                <Typography sx={{ fontSize: '1rem', fontFamily: 'Varela Round' }} color='text.primary'>
                                    You need to fill out atleast 2 deck.
                                </Typography>
                            </Alert> :
                            <Box sx={{ display: 'none' }} />}

                    </Box>
                    <Box sx={classes.muiLowerBox}>
                        {cards.map((container, index) => (
                            <motion.div
                                key={index}
                                whileHover={{ scale: 1.1 }}
                                style={{
                                    width: '100%',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Card sx={classes.muiCard}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', margin: '10px 10px 10px 10px' }}>
                                        <Typography sx={{ fontSize: '1.2rem', fontFamily: 'Varela Round', m: 1 }}>{count++}</Typography>
                                        <Box component='span' sx={{ flexGrow: 1 }} />
                                        {cards.length > 2 && (
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => { handleCardsRemove(index) }}>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </Tooltip>)}
                                    </Box>
                                    <Divider variant="middle" flexItem />
                                    <Box sx={{ display: 'flex', flexDirection: { lg: 'row', xs: 'column' }, alignItems: { lg: 'flex-start', xs: 'center' }, margin: '10px 10px 10px 10px' }}>
                                        <TextField label="Term" variant="outlined" sx={{ margin: 1, width: { lg: '45%', xS: '90%' } }} autoComplete='off' fullWidth />
                                        <TextField label="Definition" variant="outlined" sx={{ margin: 1, width: { lg: '45%', xS: '90%' } }} autoComplete='off' fullWidth />
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 80,
                                            height: 80,
                                            border: '2px dashed',
                                            borderColor: (theme) => theme.palette.secondary.main,
                                            margin: 1
                                        }}>
                                            {image === null ?
                                                (<label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" type="file" />
                                                    <Tooltip title="Add Image">
                                                        <IconButton color="secondary" aria-label="upload picture" component="span">
                                                            <AddPhotoAlternateIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </label>) :
                                                (< img src='#' alt='Some text...' />)
                                            }
                                        </Box>
                                    </Box>
                                </Card>
                            </motion.div>
                        ))}
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            style={{
                                width: '100%',
                                flexDirection: 'column',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Button sx={{ alignSelf: 'center', width: 250, borderBottom: '5px solid', borderColor: ' #EC884D' }} color='secondary' onClick={handleCardsAdd}><AddIcon />ADD CARDS</Button>
                        </motion.div>
                    </Box>
                </Box>
            </Box >
        </Box >
    )
}
