import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Avatar, Typography, IconButton, Collapse, Link } from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import PlayCircle from '@mui/icons-material/PlayCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OnReview from '../posting/OnReview'
import OnTest from '../posting/OnTest'

export default function PostBox({ postID, userID, photoURL, displayName, createdAt, title, cardCount, yearLevel }) {
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false);
    const [onReview, setOnReview] = useState(false);
    const [onTest, setOnTest] = useState(false);
    const handleChangeReview = () => {
        setChecked((prev) => !prev);
        setOnReview(true)
    };
    const handleChangeTest = () => {
        setChecked((prev) => !prev);
        setOnTest(true)
    };
    const handleDefault = () => {
        setChecked((prev) => !prev);
        setOnReview(false)
        setOnTest(false)
    };
    return (
        <Card
            elevation={5}
            sx={{
                marginTop: 3,
                width: { lg: '40vw', xs: '95vw' },
                display: 'flex',
                flexDirection: 'column',
            }}>
            <Box sx={{ alignSelf: "flex-start", flexDirection: "row", flexWrap: "wrap", display: 'flex', padding: 1 }}>
                <Link onClick={() => { navigate(`/profile/${displayName}/${userID}`) }}>
                    <Avatar sx={{ position: 'static', width: 40, height: 40, border: '2px solid', marginTop: 1, marginLeft: 1, cursor: 'pointer' }} src={photoURL} />
                </Link>
                <Box sx={{ flexDirection: "column", flexWrap: "wrap", display: 'flex', marginLeft: 2 }} >
                    <Typography sx={{ fontWeight: 500, }} color='text.disabled' >Created by</Typography>
                    <Link sx={{ fontSize: "1rem", fontWeight: 600, cursor: 'pointer' }} onClick={() => { navigate(`/profile/${displayName}/${userID}`) }}>{displayName}</Link>
                    <Typography sx={{ fontWeight: 500, }} color='text.disabled' >{createdAt}</Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', margin: '0px 15px 15px 15px', flexDirection: "column", border: '2px solid #EC884D', }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: 'text.disabled', padding: '10px 10px 10px 10px', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '1.4rem', fontFamily: 'Varela Round', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Title: {title}</Typography>
                    <Box component='span' sx={{ flexGrow: 1 }} />
                    <IconButton disabled={checked} onClick={handleChangeReview}>
                        <RateReviewIcon />
                    </IconButton>
                    <IconButton disabled={checked} onClick={handleChangeTest}>
                        <PlayCircle />
                    </IconButton>
                </Box>
                <Box sx={{ backgroundColor: (theme) => theme.palette.primary.main }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: 1 }}>
                        <Typography sx={{ fontSize: '1.1rem', fontFamily: 'Varela Round' }} color='text.secondary'>{cardCount} Cards</Typography>
                        <Box component='span' sx={{ flexGrow: 1 }} />
                        <Typography sx={{ fontSize: '1.1rem', fontFamily: 'Varela Round' }} color='text.secondary'>Level: {yearLevel}</Typography>
                    </Box>
                    <Collapse in={checked}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <IconButton disabled={!checked} onClick={handleDefault}>
                                <KeyboardArrowUpIcon fontSize='large' />
                            </IconButton>
                        </Box>
                        <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0px 15px 15px 15px', }}>
                            {onReview === true ? <OnReview getPostID={postID} /> : <div />}
                            {onTest === true ? <OnTest getPostID={postID} /> : <div />}
                        </Card>
                    </Collapse>
                </Box>
            </Box>
        </Card>)
}
