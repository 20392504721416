import React, { useState, useEffect } from 'react'
import Header from "../components/Header";
import { Grid, Box, CircularProgress, Tab, Card } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Outlet, useNavigate } from 'react-router-dom';

const classes = {
    muiRoot: {
        backgroundColor: (theme) => theme.palette.background.default,
        maxWidth: '100vw',
        minHeight: '100vh',
    },
    muiBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 15
    },
    muiComponents: {
        width: { lg: '60vw', xs: '100vw' },
        maxHeight: { lg: '100vh', xs: '100vh' },
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flexStart'
    },
    muiText: {
        fontSize: '3rem',
        fontFamily: 'Varela Round'
    },
}
export default function Settings() {
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const [payload, setPayload] = useState("userinfo");
    const tabChange = (event, newPayload) => {
        event.preventDefault();
        setPayload(newPayload);
        if (newPayload === 'userInfo') {
            navigate('/settings/edit-user-profile')
        }
        else if (newPayload === 'passinfo') {
            navigate('/settings/change-password')
        }
        else if (newPayload === 'settings') {
            navigate('/settings/edit-app-settings')
        }
        else if (newPayload === 'privacy') {
            navigate('/settings/privacy')
        }
        else {
            navigate('/settings/edit-user-profile')
        }

    };
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 1000)
    }, [])
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', backgroundColor: (theme) => theme.palette.background.default, }}>
                <CircularProgress size='30vw' thickness={3} />
            </Box>
        );
    }
    return (
        <Grid sx={classes.muiRoot}>
            <Header />
            <Box
                sx={classes.muiBox}>
                <Box
                    component={Card}
                    sx={classes.muiComponents}>
                    <TabContext value={payload}>
                        <TabList
                            orientation="vertical"
                            onChange={tabChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider', display: 'flex', minHeight: { lg: '65vh', xs: '60vh' }, height: '100%', width: { lg: '30%', xs: '25vw' } }}
                        >
                            <Tab label="Edit User Profile" value='userinfo' />
                            <Tab label="Password Settings" value='passinfo' />
                            <Tab label="Settings" value='settings' />
                            <Tab label="Privacy" value='privacy' />
                        </TabList>

                        <TabPanel value={payload} sx={{ position: 'relative' }}>
                            <Outlet />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box>
        </Grid >
    )
}
