import { Grid, Typography, Box, Card, CircularProgress } from '@mui/material'
import React, { useState, useEffect } from 'react'
import * as data from '../assets/content.json'
import Header from '../components/Header'
import Footer from "../components/Footer";
const classes = {
    muiRoot: {
        backgroundColor: (theme) => theme.palette.background.default,
        maxWidth: '100vw',
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
    },
    muiBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '70vh',
    },
    muiComponents: {
        width: { lg: '50%', xs: '90%' },
        minHeight: '50vh',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        marginTop: 5,
        border: '2px solid',
        borderColor: (theme) => !theme.palette.background.default,
    },
    muiText: {
        fontSize: '2rem',
        fontFamily: 'Varela Round',
        fontWeight: 700,
        color: (theme) => theme.palette.secondary.main,
    },
}
export default function About() {
    const content = data
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 1000)
    }, [])
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', backgroundColor: (theme) => theme.palette.background.default }}>
                <CircularProgress size='30vw' thickness={3} />
            </Box>
        );
    }
    return (
        <Grid sx={classes.muiRoot}>
            <Header />
            <Box
                sx={classes.muiBox}>
                <Card
                    sx={classes.muiComponents}>
                    <Typography sx={classes.muiText} >About</Typography>
                    <Typography color='text.primary' sx={{ fontSize: { lg: 24, xs: 18 }, textAlign: 'justify', fontFamily: 'Varela Round' }}>{content.context.about}</Typography>
                </Card>
            </Box>
            <Footer />
        </Grid>
    )
}
