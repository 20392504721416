import React, { useState } from 'react';
import {
    Typography, OutlinedInput,
    InputAdornment, IconButton, FormControl, InputLabel,
    Box, Divider, Button
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const classes = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: { lg: '40vw', xs: '60vw' },
        height: '100%',
    },
    myTextField: {
        width: { lg: '40vw', xs: '60vw' },
        margin: 1,
    },
    myTitle: {
        fontSize: '1.5rem',
        fontFamily: 'Varela Round',
        fontWeight: 'bold',
    },
}
export default function PasswordUpdate() {
    const [payload, setPayload] = useState({
        password: '',
        newPass: '',
        confirmPass: '',
        showPassword: false,
        showNPassword: false,
        showCPassword: false,
    });
    const handleChange = (prop) => (event) => {
        setPayload({ ...payload, [prop]: event.target.value });
    };
    //current
    const handleClickShowPassword = () => {
        setPayload({ ...payload, showPassword: !payload.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //new
    const handleClickShowNPassword = () => {
        setPayload({ ...payload, showNPassword: !payload.showNPassword });
    };

    const handleMouseDownNPassword = (event) => {
        event.preventDefault();
    };
    //confirm
    const handleClickShowCPassword = () => {
        setPayload({ ...payload, showCPassword: !payload.showCPassword });
    };
    const handleMouseDownCPassword = (event) => {
        event.preventDefault();
    };
    return (
        <Box container sx={classes.container}>
            <Typography sx={classes.myTitle}>Password Settings</Typography>
            <Divider style={{ width: '100%', marginBottom: 2 }} />

            <FormControl required sx={classes.myTextField} variant="outlined" size='small' color='secondary' >
                <InputLabel htmlFor="outlined-adornment-Rpassword">Current Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-Rpassword"
                    type={payload.showPassword ? 'text' : 'password'}
                    value={payload.password}
                    onChange={handleChange('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {payload.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Current Password"
                />
            </FormControl>

            <FormControl required sx={classes.myTextField} variant="outlined" size='small' color='secondary' >
                <InputLabel htmlFor="outlined-adornment-Npassword">New Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-Npassword"
                    type={payload.showNPassword ? 'text' : 'password'}
                    value={payload.newPass}
                    onChange={handleChange('newPass')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNPassword}
                                onMouseDown={handleMouseDownNPassword}
                                edge="end"
                            >
                                {payload.showNPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="New Password"
                />
            </FormControl>
            <FormControl required sx={classes.myTextField} variant="outlined" size='small' color='secondary'>
                <InputLabel htmlFor="outlined-adornment-Cpassword">New Confirm Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-Cpassword"
                    type={payload.showCPassword ? 'text' : 'password'}
                    value={payload.confirmPass}
                    onChange={handleChange('confirmPass')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowCPassword}
                                onMouseDown={handleMouseDownCPassword}
                                edge="end"
                            >
                                {payload.showCPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="New Confirm Password"
                />
            </FormControl>
            <Box sx={{ alignSelf: 'flex-end' }}>
                <Button variant="contained" color='primary' sx={{ textTransform: 'none', width: 100, alignSelf: 'flex-end', m: 1 }}>
                    <Typography sx={{ fontFamily: 'Varela Round', fontSize: '1rem' }}>Save</Typography>
                </Button>
                <Button variant="contained" color='secondary' sx={{ textTransform: 'none', width: 100, alignSelf: 'flex-end' }}>
                    <Typography sx={{ fontFamily: 'Varela Round', fontSize: '1rem' }}>Cancel</Typography>
                </Button>
            </Box>
        </Box >
    )
}
