const firebaseConfig = {
    apiKey: "AIzaSyC88KK9TYtMXnITVejveC6TvK5rGmgN8LY",
    authDomain: "reviewees-7c008.firebaseapp.com",
    projectId: "reviewees-7c008",
    storageBucket: "reviewees-7c008.appspot.com",
    messagingSenderId: "11253273293",
    appId: "1:11253273293:web:e311b4145cab08e800cf84",
    measurementId: "G-QWCNJVM2Z8"
};

export default firebaseConfig;