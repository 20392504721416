//hooks
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
//JSX.ELEMENTS
import { setLoggedInUserEmail, setLoggedInUserFB, setLoggedInUserGoogle, setLoggedInUserMicrosoft } from '../redux/actions/authAction';
//.mui
import {
    Grid, TextField, Typography, OutlinedInput,
    InputAdornment, IconButton, FormControl,
    InputLabel, Button, Alert
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ImGoogle2, ImFacebook2 } from 'react-icons/im'
import { TiVendorMicrosoft } from 'react-icons/ti'

const classes = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    myTextField: {
        width: 300,
        margin: 1,
    },
    myTitle: {
        fontSize: '3rem',
        fontFamily: 'Varela Round',
        fontWeight: 'bold'
    },
    myText: {
        fontSize: '1rem',
        opacity: '0.7',
        textAlign: 'center',
        fontFamily: 'Varela Round',
    },
    myLabels: {
        fontSize: '1rem',
        paddingLeft: 1,
        textAlign: 'center',
        fontFamily: 'Varela Round',
    },
}

export default function Login() {
    const dispatch = useDispatch();
    const error = useSelector(state => state.user.error)
    const [payload, setPayload] = useState({
        email: '',
        password: '',
        showPassword: false,
    });
    const handleChange = (prop) => (event) => {
        setPayload({ ...payload, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setPayload({
            ...payload,
            showPassword: !payload.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleGoogleLogin = (e) => {
        dispatch(setLoggedInUserGoogle())
    }
    const handleMSLogin = (e) => {
        dispatch(setLoggedInUserMicrosoft())
    }
    const handleFBLogin = (e) => {
        dispatch(setLoggedInUserFB())
    }
    const handleEmailLogin = (e) => {
        dispatch(setLoggedInUserEmail(payload.email, payload.password))
    }
    return (
        <Grid container sx={classes.container}>
            {error && (< Alert severity="error" style={{ margin: 10 }}>
                {"Error - " + error}
            </Alert>)}

            <Typography sx={classes.myTitle}>Sign In</Typography>

            <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                <IconButton color='inherit' sx={{ m: 1, textTransform: 'none' }} onClick={handleFBLogin}>
                    <ImFacebook2 style={{ color: '#5375e2' }} />
                </IconButton>
                <IconButton color='inherit' sx={{ m: 1, textTransform: 'none' }} onClick={handleGoogleLogin}>
                    <ImGoogle2 style={{ color: '#5375e2' }} />
                </IconButton>
                <IconButton color='inherit' sx={{ m: 1, textTransform: 'none' }} onClick={handleMSLogin}>
                    <TiVendorMicrosoft style={{ color: '#5375e2' }} />
                </IconButton>
            </Grid>
            <Typography sx={classes.myText}>or login</Typography>

            <TextField sx={classes.myTextField} label="Email" variant="outlined" color='secondary' value={payload.email} onChange={handleChange('email')} />

            <FormControl sx={classes.myTextField} variant="outlined" color='secondary'>
                <InputLabel htmlFor="outlined-adornment-Lpassword">Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-Lpassword"
                    type={payload.showPassword ? 'text' : 'password'}
                    value={payload.password}
                    onChange={handleChange('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {payload.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>

            <Button variant="contained"
                color='primary'
                sx={{ textTransform: 'none' }}
                onClick={handleEmailLogin}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleEmailLogin();
                    }
                }}>
                <Typography sx={{ fontFamily: 'Varela Round', fontSize: '1rem' }}>Login</Typography>
            </Button>

        </Grid>
    )
}
