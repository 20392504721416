//hooks
import React, { useState, useEffect } from 'react'
//JSX.ELEMETS
import { useSelector, useDispatch } from 'react-redux';
//.mui
import {
    TextField, Typography,
    FormControl,
    Button, Box, useMediaQuery, FormLabel, RadioGroup,
    FormControlLabel, Radio, Divider, Tooltip
} from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MobileDatePicker, LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { getUserInfo } from '../../redux/actions/authAction';

const classes = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: { lg: '40vw', xs: '60vw' },
        height: '100%',
    },
    myTextField: {
        width: { lg: '40vw', xs: '60vw' },
        margin: 1,
    },
    myTitle: {
        fontSize: '1.5rem',
        fontFamily: 'Varela Round',
        fontWeight: 'bold',
    },
    myText: {
        marginBottom: 1,
        fontSize: '1rem',
        opacity: '0.7',
        textAlign: 'center',
        fontFamily: 'Varela Round',
    },
    myLabels: {
        fontSize: '1rem',
        paddingLeft: 1,
        textAlign: 'center',
        fontFamily: 'Varela Round',
    },
}

export default function UserInfoUpdate() {
    const matchXS = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    const [birthDate, setBirthDate] = useState(user.currentUser.birthDate === "" ? new Date('2014-08-18') : user.currentUser.birthDate);
    const [payload, setPayload] = useState({
        firstName: user.currentUser.firstName,
        lastName: user.currentUser.lastName,
        email: user.currentUser.email,
        password: '',
        newPass: '',
        confirmPass: '',
        gender: user.currentUser.gender === "" ? "" : user.currentUser.gender,
        showPassword: false,
        showNPassword: false,
        showCPassword: false,
    });

    const handleDate = (newValue) => {
        setBirthDate(newValue);
    };
    const handleChange = (prop) => (event) => {
        setPayload({ ...payload, [prop]: event.target.value });
    };
    useEffect(() => {
        dispatch(getUserInfo())
    }, [dispatch])
    return (
        <Box container sx={classes.container}>
            <Typography sx={classes.myTitle}>User Settings</Typography>
            <Divider style={{ width: '100%', marginBottom: 2 }} />
            <TextField
                required
                inputProps={{ sx: { textTransform: 'capitalize' } }}
                label="First Name"
                variant="outlined"
                size='small'
                sx={classes.myTextField}
                color='secondary'
                value={payload.firstName}
                onChange={handleChange('firstName')}
            />
            <TextField
                required
                inputProps={{ sx: { textTransform: 'capitalize' } }}
                label="Last Name"
                variant="outlined"
                size='small'
                sx={classes.myTextField}
                color='secondary'
                value={payload.lastName}
                onChange={handleChange('lastName')}
            />
            <TextField
                disabled
                sx={classes.myTextField}
                label="Email"
                variant="outlined"
                size='small'
                color='secondary'
                value={payload.email}
                onChange={handleChange('email')}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {!matchXS ?
                        <MobileDatePicker
                            label="Birthdate"
                            inputFormat="MM/dd/yyyy"
                            maxDate={new Date()}
                            minDate={new Date('1900-01-01')}
                            value={birthDate}
                            onChange={handleDate}
                            renderInput={(params) => <TextField {...params} size='small' color='secondary' sx={{
                                width: '55vw',
                                margin: 1,
                            }} />}
                        />
                        :
                        <DesktopDatePicker
                            label="Birthdate"
                            inputFormat="MM/dd/yyyy"
                            maxDate={new Date()}
                            minDate={new Date('1900-01-01')}
                            value={birthDate}
                            onChange={handleDate}
                            renderInput={(params) => <TextField {...params} size='small' color='secondary' sx={{
                                width: '39vw',
                                margin: 1,
                            }} />}
                        />
                    }
                </LocalizationProvider>
                {user.currentUser.birthDate === '' ? <Tooltip title="Please update your birthdate!" placement='top-start'><WarningAmberIcon color='warning' /></Tooltip> : ''}
            </Box>
            <FormControl required component="fieldset">
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <FormLabel component="legend" >Gender</FormLabel>
                    {user.currentUser.gender === '' ? <Tooltip title="Please update your gender!" placement='top-start'><WarningAmberIcon color='warning' /></Tooltip> : ''}
                </Box>
                <RadioGroup row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    sx={{ width: { lg: '40vw', xs: '60vw' } }}
                    value={payload.gender}
                    onChange={handleChange('gender')}>
                    <FormControlLabel value="male" control={<Radio color='secondary' />} label="Male" />
                    <FormControlLabel value="female" control={<Radio color='secondary' />} label="Female" sx={{ marginLeft: { lg: 13, xs: 3 } }} />
                </RadioGroup>
            </FormControl>

            <Box sx={{ alignSelf: 'flex-end', }}>
                <Button variant="contained" color='primary' sx={{ textTransform: 'none', width: 100, alignSelf: 'flex-end', m: 1 }}>
                    <Typography sx={{ fontFamily: 'Varela Round', fontSize: '1rem' }}>Save</Typography>
                </Button>
                <Button variant="contained" color='secondary' sx={{ textTransform: 'none', width: 100, alignSelf: 'flex-end' }}>
                    <Typography sx={{ fontFamily: 'Varela Round', fontSize: '1rem' }}>Cancel</Typography>
                </Button>
            </Box>
        </Box>
    )
}
