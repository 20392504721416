//hooks
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//JSX.ELEMENTS
import ModeSwitch from './ModeSwitch';
import { getUserInfo, setLoggedOutState } from '../../redux/actions/authAction';
//.mui
import {
    IconButton,
    List,
    Drawer,
    ListItem,
    ListItemText,
    ListItemIcon,
    Box,
    Avatar,
    Typography
} from '@mui/material';
//icons
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

const classes = {
    muiRoot: { display: { lg: 'none', md: 'none', xs: 'block', sm: 'block' } },
    menuIconContainer: {
        margin: 'auto',
    },
    iconStyle: {
        fontSize: 24,
        mr: 3,
        color: 'black'
    },
    paper: {
        backgroundColor: (theme) => theme.palette.background.default,
        height: "100%",
    },
    textStyle: {
        fontSize: 24,
        mr: 2,
    },
};

export default function SideDrawer() {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const navigate = useNavigate()
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleMenuClose = () => {
        setOpenDrawer(false)
    };

    useEffect(() => {
        dispatch(getUserInfo())
    }, [dispatch])

    const handleClickLogOut = () => {
        handleMenuClose()
        dispatch(setLoggedOutState())
    }
    return (
        <Box sx={classes.muiRoot}>
            <Drawer
                anchor='left'
                onClose={handleMenuClose}
                open={openDrawer}
            >
                <Typography sx={{
                    fontSize: 24,
                    mr: 2,
                    textAlign: 'center',
                    backgroundColor: '#1F7750',
                    width: '100%'
                }} color='text.primary'>Menu</Typography>
                <List sx={classes.paper}>
                    <ListItem
                        button
                    >
                        <ListItemIcon onClick={() => { navigate(`/profile/${user.currentUser.displayName}/${user.currentUser.id}`) }}>
                            {user.currentUser.photoURL === "" ?
                                (< AccountCircle sx={{
                                    fontSize: 24,
                                    mr: 3,
                                }} color='text.primary' />) :
                                (<Avatar sx={{ width: 30, height: 30, mr: 2, border: 2, borderColor: (theme) => theme.palette.text.primary }} src={user.currentUser.photoURL} />)}
                            <ListItemText sx={classes.textStyle}>Profile</ListItemText>
                        </ListItemIcon>
                    </ListItem>
                    <ListItem>
                        <ModeSwitch />
                    </ListItem>
                    <ListItem
                        button
                    >
                        <ListItemIcon onClick={() => { navigate('/about') }}>
                            <InfoIcon sx={{
                                fontSize: 24,
                                mr: 3,
                            }} color='text.primary' />
                            <ListItemText sx={classes.textStyle}>About</ListItemText>
                        </ListItemIcon>
                    </ListItem>
                    <ListItem
                        button
                    >
                        <ListItemIcon onClick={() => { navigate('/settings/edit-user-profile') }}>
                            <Settings sx={{
                                fontSize: 24,
                                mr: 3,
                            }} color='text.primary' />
                            <ListItemText sx={classes.textStyle}>Settings</ListItemText>
                        </ListItemIcon>
                    </ListItem>
                    <ListItem
                        button
                    >
                        <ListItemIcon onClick={handleClickLogOut}>
                            <Logout sx={{
                                fontSize: 24,
                                mr: 3,
                            }} color='text.primary' />
                            <ListItemText sx={classes.textStyle}>Log Out</ListItemText>
                        </ListItemIcon>
                    </ListItem>
                </List>
            </Drawer>
            <IconButton
                sx={classes.menuIconContainer}
                onClick={() => setOpenDrawer(!openDrawer)}
            >
                {!openDrawer ? <MenuIcon sx={classes.iconStyle} /> : <MenuOpenIcon sx={classes.iconStyle} />}
            </IconButton>
        </Box >
    )
}
