//hooks
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
//JSX.ELEMENTS
import RouterComponent from './routerfolder/RouterComponent'
import theme from '../src/assets/utils/theme'
//.mui
import { ThemeProvider, createTheme } from '@mui/material'
import { getTheme } from './redux/actions/uiAction';
import { getUserInfo } from './redux/actions/authAction';
import { auth } from './assets/utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';

export default function App() {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const THEME = createTheme(theme(ui.isDarkMode));
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user === null) {
        dispatch(getUserInfo())
      }
    })
    dispatch(getTheme());
  }, [dispatch]);
  return (
    <ThemeProvider theme={THEME}>
      <RouterComponent />
    </ThemeProvider>
  )
}
