//hooks
import React, { useState, useEffect } from 'react'
import { motion } from "framer-motion";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
//JSX.ELEMENTS
import Header from '../Header';
//.mui
import {
    Card, TextField, Box, IconButton, Typography, Tooltip, Divider, Button, Alert, MenuItem, Select, InputLabel, FormControl
} from '@mui/material'
//icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddIcon from '@mui/icons-material/Add';
import ReplyIcon from '@mui/icons-material/Reply'
//firebase
import { collection, doc, addDoc, Timestamp, updateDoc, increment } from 'firebase/firestore';
import { auth, db } from '../../assets/utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getUserInfo } from '../../redux/actions/authAction';

const classes = {
    muiRoot: {
        backgroundColor: (theme) => theme.palette.background.default,
        maxWidth: '100vw',
        minHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
    },
    muiBox: {
        display: 'flex',
        justifyContent: 'center',
        padding: 2
    },
    muiComponents: {
        width: { lg: '50vw', xs: '100vw' },
        minHeight: '50vh',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    muiUpperBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 2
    },
    muiLowerBox: {
        width: '100%',
        minHeight: '50vh',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15vh'
    },
    muiCard: {
        width: '100%',
        height: { lg: 180, xs: 250 },
        flexDirection: 'column',
        display: 'flex',
        margin: 2,
        justifyContent: 'center',
    },
    cookieAlert: {
        margin: 1,
        width: '80%',
        alignItems: 'center',
        "& .MuiAlert-icon": {
            fontSize: 32
        }
    }
}
export default function CreateSets() {
    const dispatch = useDispatch();
    const { folderId } = useParams();
    let count = 1;

    const [payload, setPayload] = useState({
        title: "",
        description: "",
        forYear: 'Any'
    })
    const [inputFields, setInputFields] = useState([
        {
            term: '',
            definition: '',
        },
        {
            term: '',
            definition: '',
        },
    ])
    const handleChangeInput = (index, event) => {
        const values = [...inputFields];
        values[index][event.target.name] = event.target.value;
        setInputFields(values);
    }
    useEffect(() => {
        dispatch(getUserInfo());
    }, [dispatch])
    const handleSubmit = (e) => {
        e.preventDefault();
        const cleanUp = onAuthStateChanged(auth, (user) => {
            const folderRef = doc(db, 'users', user.uid, 'userFolder', folderId);
            const setsRef = collection(db, 'users', user.uid, 'userFolder', folderId, 'userDecks')
            if (user !== null) {
                addDoc(setsRef, {
                    title: payload.title,
                    description: payload.description,
                    yearLevel: payload.forYear,
                    createdAt: moment(Timestamp.now().toDate()).fromNow(),
                    cardCount: inputFields.length
                }).then((docRef) => {
                    inputFields.forEach((data) => {
                        addDoc(collection(docRef, 'userCards'), {
                            term: data.term,
                            definition: data.definition,
                        })
                    })
                })
                updateDoc(doc(db, 'users', user.uid), {
                    totalSets: increment(1)
                })
                updateDoc(folderRef, {
                    setCount: increment(1)
                }).then(() => { window.location.replace(`/folders/${folderId}/decks`) })
            }
        })
        return () => cleanUp()
    }
    const handleAddFields = () => {
        setInputFields([...inputFields, { term: '', definition: '' }])
    }
    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1)
        setInputFields(values);
    }
    const handleChange = (prop) => (event) => {
        setPayload({ ...payload, [prop]: event.target.value });
    };
    return (
        <Box sx={classes.muiRoot}>
            <Header />
            <Box sx={classes.muiBox}>
                <Box sx={classes.muiComponents}>
                    <Box sx={classes.muiUpperBox}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100% ', marginTop: 4, marginBottom: 2 }}>
                            <Tooltip title="Return">
                                <IconButton size='medium' onClick={() => { window.location.replace(`/folders/${folderId}/decks`) }}>
                                    <ReplyIcon />
                                </IconButton>
                            </Tooltip>
                            <Box component='span' sx={{ flexGrow: 1 }} />
                            <Typography sx={{ fontSize: '2rem', fontFamily: 'Varela Round' }} color='text.primary'>Create New Deck</Typography>
                            <Box component='span' sx={{ flexGrow: 1 }} />
                            <Button disabled={(inputFields.length <= 2) || (inputFields[inputFields.length - 1].term === '' || inputFields[inputFields.length - 1].definition === '')} variant='contained' sx={{ maxHeight: 50 }} onClick={e => handleSubmit(e)}>CREATE</Button>
                        </Box>
                        <TextField
                            label="Title"
                            variant="outlined"
                            sx={{ margin: 1, width: '90%' }}
                            size='small'
                            autoComplete='off'
                            inputProps={{ maxLength: 25 }}
                            onChange={handleChange('title')}
                            value={payload.title}
                        />
                        <TextField
                            sx={{ margin: 1, width: '90%' }}
                            size='small'
                            id="outlined-multiline-static"
                            label="Description"
                            autoComplete='off'
                            multiline
                            rows={2}
                            onChange={handleChange('description')}
                            value={payload.description}
                        />
                        <Box sx={{ minWidth: 150 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Year Level</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={payload.forYear}
                                    label="Year Level"
                                    onChange={handleChange('forYear')}
                                >
                                    <MenuItem value='Any'>Any</MenuItem>
                                    <MenuItem value='Grade 1'>Grade 1</MenuItem>
                                    <MenuItem value='Grade 2'>Grade 2</MenuItem>
                                    <MenuItem value='Grade 3'>Grade 3</MenuItem>
                                    <MenuItem value='Grade 4'>Grade 4</MenuItem>
                                    <MenuItem value='Grade 5'>Grade 5</MenuItem>
                                    <MenuItem value='Grade 6'>Grade 6</MenuItem>
                                    <MenuItem value='Grade 7'>Grade 7</MenuItem>
                                    <MenuItem value='Grade 8'>Grade 8</MenuItem>
                                    <MenuItem value='Grade 9'>Grade 9</MenuItem>
                                    <MenuItem value='Grade 10'>Grade 10</MenuItem>
                                    <MenuItem value='Grade 11'>Grade 11</MenuItem>
                                    <MenuItem value='Grade 12'>Grade 12</MenuItem>
                                    <MenuItem value='College Year 1'>College Year 1</MenuItem>
                                    <MenuItem value='College Year 2'>College Year 2</MenuItem>
                                    <MenuItem value='College Year 3'>College Year 3</MenuItem>
                                    <MenuItem value='College Year 4'>College Year 4</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        {inputFields.length <= 2 ?
                            <Alert severity="info" sx={classes.cookieAlert}>
                                <Typography sx={{ fontSize: '1rem', fontFamily: 'Varela Round' }} color='text.primary'>
                                    You need to fill out more than 2 card.
                                </Typography>
                            </Alert> :
                            <Box sx={{ display: 'none' }} />}

                    </Box>
                    <Box sx={classes.muiLowerBox}>
                        {inputFields.map((inputField, index) => (
                            <motion.form
                                key={index}
                                onSubmit={handleSubmit}
                                whileHover={{ scale: 1.1 }}
                                style={{
                                    width: '100%',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Card sx={classes.muiCard}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', margin: '10px 10px 10px 10px' }}>
                                        <Typography sx={{ fontSize: '1.2rem', fontFamily: 'Varela Round', m: 1 }}>{count++}</Typography>
                                        <Box component='span' sx={{ flexGrow: 1 }} />
                                        {inputFields.length > 2 && (
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => handleRemoveFields(index)}>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </Tooltip>)}
                                    </Box>
                                    <Divider variant="middle" flexItem />
                                    <Box sx={{ display: 'flex', flexDirection: { lg: 'row', xs: 'column' }, alignItems: { lg: 'flex-start', xs: 'center' }, margin: '10px 10px 10px 10px' }}>
                                        <TextField
                                            label="Term"
                                            name="term"
                                            variant="outlined"
                                            sx={{ margin: 1, width: { lg: '45%', xS: '90%' } }}
                                            autoComplete='off'
                                            fullWidth
                                            value={inputField.term}
                                            onChange={event => handleChangeInput(index, event)}
                                        />
                                        <TextField
                                            label="Definition"
                                            name="definition"
                                            variant="outlined"
                                            sx={{ margin: 1, width: { lg: '45%', xS: '90%' } }}
                                            autoComplete='off'
                                            fullWidth
                                            value={inputField.definition}
                                            onChange={event => handleChangeInput(index, event)}
                                        />
                                    </Box>
                                </Card>
                            </motion.form>
                        ))}
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            style={{
                                width: '100%',
                                flexDirection: 'column',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            < Button
                                disabled={
                                    inputFields[0].term === '' ||
                                    inputFields[0].definition === '' ||
                                    inputFields[1].term === '' ||
                                    inputFields[1].definition === ''}
                                sx={{
                                    alignSelf: 'center',
                                    width: 250,
                                    borderBottom: '5px solid',
                                    borderColor: ' #EC884D'
                                }}
                                color='secondary'
                                onClick={() => handleAddFields()}>
                                <AddIcon />ADD CARDS
                            </Button>
                        </motion.div>
                    </Box>
                </Box>
            </Box >
        </Box >
    )
}
