//hooks
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
//JSX.ELEMETS
import cover from '../assets/images/cover.jpg'
import background from '../assets/images/backGround.jpg'
//.mui
import { Grid, Box, Card, Typography, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'


const classes = {
    muiRoot: {
        background: (theme) => theme.palette.background.default,
        maxWidth: '100vw',
        minHeight: '100vh',
    },
    muiGridContainer: {
        display: 'flex',
        flexDirection: { lg: 'row', md: 'row', xs: 'column' },
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        minHeight: '100vh',
        background: (theme) => theme.palette.background.default,
    },
    muiCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: { lg: '30vw', md: '40vw', sm: '80vw', xs: '90vw' },
        height: '100%',
        border: '3px solid #639BC8',
        padding: '0px 2px 2px 2px',
        opacity: 0.93
    },
    muiBoxLeft: {
        width: { lg: '50vw', xs: '100vw' },
        minHeight: '50vh',
        flexDirection: 'column',
        alignItems: { lg: 'flex-start', xs: 'center' },
        justifyContent: 'center',
        display: 'flex',
        margin: { xs: 1 }
    },
    muiBoxRight: {
        width: { lg: '30vw', md: '40vw', sm: '80vw', xs: '90vw' },
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        background: `url(${background})`,
        margin: { xs: 1 }
    },
    muiImage: {
        width: '80%',
        height: '80%',
        borderRadius: '20px',
        border: '3px solid #639BC8',
    },
    indicator: {
        maxWidth: { lg: '25vw', md: '50vw', sm: '75vw', xs: '85vw' },
        height: '4px',
    },
    tabtextStyle: {
        textTransform: "none",
        textAlign: 'center',
        fontSize: 18,
        width: { lg: 300, md: 250, sm: '50vw', xs: 200 },
        fontFamily: 'Varela Round',
        '&:hover': {
            color: (theme) => theme.palette.secondary.main
        },
    },
}
export default function WelcomePage() {
    const navigate = useNavigate()
    const [payload, setPayload] = useState("1");
    useEffect(() => {
        localStorage.setItem("tabPayload", payload)
    }, [payload])

    useEffect(() => {
        const tabPayload = localStorage.getItem("tabPayload")
        setPayload(tabPayload)
        window.location.pathname === "/landpage/login" ? setPayload("1") : setPayload("2")
    }, [])

    const tabChange = (event, newPayload) => {
        event.preventDefault();
        setPayload(newPayload);
        newPayload === "1" ? navigate("/landpage/login") : navigate("/landpage/registration")
        console.log(newPayload)
    };
    return (
        <Grid sx={classes.muiRoot}>
            <Grid sx={classes.muiGridContainer}>
                <Box sx={classes.muiBoxLeft}>
                    <img src={cover} draggable='false' style={classes.muiImage} alt='welcomepage' />
                    <Typography sx={{ fontSize: 48, color: '#EC884D', fontFamily: 'Varela Round', textAlign: { xs: 'center' } }}>Reviewees</Typography>
                    <Typography sx={{ fontSize: 18, color: '#639BC8', fontFamily: 'Varela Round', textAlign: { xs: 'center' } }}>Execise your brain in an extraordinary way~!</Typography>
                </Box>
                <Box sx={classes.muiBoxRight}>
                    <Card sx={classes.muiCard}>
                        <TabContext value={payload}>
                            <TabList onChange={tabChange} aria-label="lab API tabs example"
                                TabIndicatorProps={{
                                    sx: classes.indicator,
                                }}>
                                <Tab disableRipple label="Sign In" value="1" sx={classes.tabtextStyle} />
                                <Tab disableRipple label="Sign Up" value="2" sx={classes.tabtextStyle} />
                            </TabList>
                            <TabPanel value={payload} >
                                <Outlet />
                            </TabPanel>
                        </TabContext>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    )
}
