import React, { useState } from 'react';
import {
    Box,
    Typography,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import TwitterIcon from '@mui/icons-material/Twitter';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const style = {
    root: {
        alignItems: "center",
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    column: {
        display: 'flex',
        flexDirection: "column",
        textAlign: "center",
        padding: {lg: 7, xs: 3 },
        justifyContent: 'center',
    },
    text1: {
        color: (theme) => theme.palette.secondary.main,
        fontWeight: 800,
        fontSize: { lg: "25px", xs: "20px" },
        margin: 2,
    },
    text2: {
        color: 'text.primary',
        fontSize: { lg: "20px", xs: "15px" },
        
    },
    contactContainer: {
        flexDirection: "column",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
    },
    contactText: {
        color: (theme) => theme.palette.secondary.main,
        fontWeight: 800,
        fontSize: "25px",
        margin: 2,
    },
    iconContainer: {
        flexDirection: "row",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icons: {
        marginRight: "20px",
        marginLeft: "20px",
        color: 'text.primary',
        size: "15px"
    },
    formControl: {
        width: 200,
        border: "none",
    }
}

export default function Footer() {

    const [language, setLanguage] = useState('');
    const handleChange = (event) => {
        setLanguage(event.target.value);
    }
    return (
        <Box sx={style.root}>
           <Box sx={style.row}>
               <Box sx={style.column}>
                   <Typography sx={style.text1}>Help</Typography>
                   <Typography sx={style.text2}>Help Center</Typography>
                   <Typography sx={style.text2}>Community Guidelines</Typography>
                   <Typography sx={style.text2}>Teachers</Typography>
               </Box>
               <Box sx={style.column}>
                   <Typography sx={style.text1}>About</Typography>
                   <Typography sx={style.text2}>Blog</Typography>
                   <Typography sx={style.text2}>Privacy</Typography>
                   <Typography sx={style.text2}>Terms and Policy</Typography>
               </Box>
               <Box sx={style.column}>
                    <FormControl sx={style.formControl}>
                        <Typography align="justify" sx={style.text1}>Language</Typography>
                        <Select
                        defaultValue={10}
                        onChange={handleChange}
                        >
                        <MenuItem value={10}><em>English (USA)</em></MenuItem>
                        <MenuItem value={20}>English (UK)</MenuItem>
                        <MenuItem value={30}>Filipino</MenuItem>
                        <MenuItem value={40}>Visaya</MenuItem>
                        <MenuItem value={50}>Chinese</MenuItem>
                        </Select>
                    </FormControl>
               </Box>
           </Box>
           <Box sx={style.contactContainer}>
               <Typography sx={style.contactText}>Contact Us</Typography>
               <Box sx={style.iconContainer}>
                   <FacebookIcon sx={style.icons} />
                   <GoogleIcon sx={style.icons} />
                   <TwitterIcon  sx={style.icons}/>
               </Box>
           </Box>
        </Box>
    )
}
