import React, { useEffect, useState } from 'react';
import { Card, Stepper, Typography, Box, Tooltip, IconButton, CircularProgress, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReplyIcon from '@mui/icons-material/Reply';
import { auth, db } from '../../assets/utils/firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';

const classes = {
    muiBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        minHeight: '90vh',
        flexDirection: 'column',
    },
    muiContainer: {
        backgroundColor: (theme) => theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minWidth: { lg: '40vw', xs: '90vw' },
        minHeight: { lg: '50vh', xs: '65vh' },
        padding: { lg: 2, xs: 0 },
        margin: 1
    },
    muiHelpers: {
        width: { lg: '50%', xs: '96%' },
        minHeight: '5vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 2
    },
    muiCard: {
        backgroundColor: '#1F7750',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: { lg: '40vw', xs: '86vw' },
        height: { lg: '50vh', xs: '50vh' },
        padding: 1,
        margin: 1,
        position: 'relative'
    },
    withImage: { display: 'flex', width: '320px', height: '320px', alignSelf: 'center' },
    withoutImage: { display: 'none', width: '320px', height: '320px', alignSelf: 'center' }
}


export default function DisplayTest() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    let image = ''
    //DATA
    const [activeStep, setActiveStep] = useState(0);
    const [maxSteps, setmaxSteps] = useState()
    const { folderId, setId } = useParams();
    const handlePageBack = () => {
        navigate(`/folders/${folderId}/decks`)
        localStorage.removeItem('myCards')
    }
    //card-Actions
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        const setup = onAuthStateChanged(auth, (user) => {
            const userCards = collection(db, 'users', user.uid, 'userFolder', folderId, 'userDecks', setId, 'userCards')
            onSnapshot(userCards, (snapshot) => {
                let myCards = [];
                snapshot.forEach((doc) => {
                    myCards.push({
                        id: doc.id,
                        term: doc.get('term'),
                        definition: doc.get('definition'),
                        image: doc.get('image')
                    })
                })
                setmaxSteps(snapshot.size)
                localStorage.setItem('myCards', JSON.stringify(myCards))
                setIsLoading(false)
            })
        })
        return () => setup()
    }, [folderId, setId])

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', width: '100vw', backgroundColor: (theme) => theme.palette.background.default, }}>
                <CircularProgress size='30vw' thickness={3} />
            </Box>
        );
    }
    var myCards = localStorage.getItem("myCards")
    var data = JSON.parse(myCards)[activeStep]
    return (
        <Stepper activeStep={maxSteps} alternativeLabel>
            <Box sx={classes.muiBox}>
                <Card sx={classes.muiContainer} >
                    <Box sx={{ display: 'flex', alignSelf: 'center', width: { lg: '42vw', xs: '100vw' } }}>
                        <Tooltip title="Return">
                            <IconButton size='medium' onClick={handlePageBack}>
                                <ReplyIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Card sx={classes.muiCard}>
                        <Box sx={image === '' ? classes.withoutImage : classes.withImage} >
                            <img src={image} width='100%' alt='' />
                        </Box>
                        <Typography sx={{ fontSize: { lg: '1.8rem', xs: '1.2rem' }, fontFamily: 'Varela Round', textAlign: 'center', marginTop: 2 }}>{data.definition}</Typography>
                        <Box sx={{ position: 'absolute', bottom: 0, display: 'flex', flexDirection: 'column', width: '96%', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                <Button variant='contained' color='primary' sx={{ width: '40%', textTransform: 'none', textAlign: 'left' }}>A. Camera</Button>
                                <Button variant='contained' color='secondary' sx={{ width: '40%', textTransform: 'none', textAlign: 'left' }}>B. Lens</Button>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', m: 1 }}>
                                <Button variant='contained' color='secondary' sx={{ width: '40%', textTransform: 'none', textAlign: 'left' }}>C. Image</Button>
                                <Button variant='contained' color='primary' sx={{ width: '40%', textTransform: 'none', textAlign: 'left' }}>D. Sample</Button>
                            </Box>
                        </Box>

                    </Card>
                    <Box sx={classes.muiHelpers}>
                        <IconButton disabled={activeStep === 0} size='medium' onClick={handleBack}>
                            <ArrowBackIcon fontSize='large' />
                        </IconButton>

                        <Box component='span' sx={{ flexGrow: .2 }} />

                        <IconButton disabled={activeStep === maxSteps - 1} size='medium' onClick={handleNext}>
                            <ArrowForwardIcon fontSize='large' />
                        </IconButton>
                    </Box>
                </Card>
            </Box>
        </Stepper>

    )
}
