import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
import PostBox from '../components/posting/PostBox';
import {
    Grid, Box, Typography, Card, Avatar, CircularProgress, Tooltip, IconButton, Button, Snackbar,
} from '@mui/material';
import backGround from "../assets/images/try.png"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { collection, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../assets/utils/firebase';


const classes = {
    muiRoot: {
        backgroundColor: (theme) => theme.palette.background.default,
        maxWidth: '100vw',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    muiBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        margin: 2,
    },
    muiComponents: {
        width: '100%',
        minHeight: '30vh',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        background: `url(${backGround})`,
    },
    muiContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    muiPostBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 1
    },
    muiName: {
        fontSize: '2rem',
        marginLeft: 1,
        fontFamily: 'Varela Round',
        textAlign: 'center'
    },
    muiText: {
        fontSize: 24,
        marginLeft: 1,
        fontFamily: 'Varela Round',
        textAlign: 'center'
    },
    cameraIcon: {
        float: 'right',
        marginTop: -30
    },
}

export default function Profile() {
    const [currentUser, setCurrentUser] = useState({})
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const [posts, setPosts] = useState([]);
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={() => { navigator.clipboard.writeText(''); }}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    useEffect(() => {
        const userRef = doc(db, 'users', id)
        getDoc(userRef).then((docSnap) => {
            setCurrentUser(docSnap.data())
        })
        setIsLoading(false)
    }, [id])

    useEffect(() => {
        const postRef = collection(db, 'posts');
        const cleanup = onSnapshot(postRef, (snapshot) => {
            setPosts(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        })
        setTimeout(() => setIsLoading(false), 1000)
        return () => cleanup()
    }, []);
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', backgroundColor: (theme) => theme.palette.background.default, }}>
                <CircularProgress size='30vw' thickness={3} />
            </Box>
        );
    }
    return (
        <Grid sx={classes.muiRoot}>
            <Header />
            < Box sx={classes.muiBox}>
                <Card sx={classes.muiComponents}>
                    <Box>
                        <Avatar sx={{ position: 'static', width: 120, height: 120, border: '3px solid' }} src={currentUser.photoURL} />
                    </Box>
                    <Box sx={classes.muiPostBox}>
                        <Typography sx={classes.muiName} color='text.primary'>{currentUser.displayName}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{}} color='text.primary'>ID:{id}</Typography>
                            <Tooltip title="Copy">
                                <IconButton onClick={() => { navigator.clipboard.writeText(id); setOpen(true) }}>
                                    <ContentCopyIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>

                    </Box>
                </Card>
                <Box sx={{ display: 'flex', flexDirection: { lg: 'row', xs: 'column' }, alignSelf: 'center', justifyContent: 'space-evenly', width: '100%', padding: 2 }}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', height: '40vh', minWidth: '30%', marginTop: 2 }}>
                        <Box sx={classes.muiContainer}>
                            <Box sx={classes.muiPostBox}>
                                <Typography sx={classes.muiText} color='text.primary'>Groups</Typography>
                                <Typography color='text.primary'>{currentUser.totalGroups}</Typography>
                            </Box>
                            <Box sx={classes.muiPostBox}>
                                <Typography sx={classes.muiText} color='text.primary'>Study Sets</Typography>
                                <Typography color='text.primary'>{currentUser.totalSets}</Typography>
                            </Box>
                            <Box sx={classes.muiPostBox}>
                                <Typography sx={classes.muiText} color='text.primary'>Posts</Typography>
                                <Typography color='text.primary'>{currentUser.totalPosts}</Typography>
                            </Box>
                        </Box>
                    </Card>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '50%' }}>
                        {posts.map(({ id, userID, photoURL, displayName, createdAt, title, cardCount, yearLevel }) => (
                            <PostBox
                                key={id}
                                postID={id}
                                userID={userID}
                                photoURL={photoURL}
                                displayName={displayName}
                                createdAt={createdAt}
                                title={title}
                                yearLevel={yearLevel}
                                cardCount={cardCount}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>

            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Copied"
                action={action}
            />
            <Footer />
        </Grid >
    )
}
