//hooks
import React, { useState } from 'react';
//.mui
import { Backdrop, Box, Modal, Fade, Button, Typography, TextField } from '@mui/material';
//icons
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { auth, db } from '../../assets/utils/firebase';
import moment from 'moment';
import { onAuthStateChanged } from 'firebase/auth';

const classes = {
    muiUpperComponents: {
        width: { lg: '60%', xs: '100%' },
        minHeight: '10vh',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 2
    },
    muiButtonLabel: {
        fontSize: '2rem',
        verticalAlign: 'middle',
        fontFamily: 'Varela Round'
    },
    muiModalComponent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { lg: '40vw', xs: '80vw' },
        minHeight: '30vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        flexDirection: 'column',
        display: 'flex'
    },
    muiButton: {
        width: '90%',
        border: '2px dashed',
        borderColor: (theme) => theme.palette.secondary.main,
        minHeight: '10vh',
        textTransform: 'none'
    }
}
export default function CreateFolder() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [category, setCategory] = useState('')
    const [description, setDescription] = useState('')
    const onCreate = () => {
        onAuthStateChanged(auth, (user) => {
            const folderRef = collection(db, 'users', user.uid, 'userFolder')
            if (user !== null) {
                addDoc(folderRef, {
                    category: category,
                    description: description,
                    createdAt: moment(Timestamp.now().seconds * 1000).fromNow(),
                    setCount: 0
                })
            }
        })
        setCategory('')
        setDescription('')
        handleClose()
    }
    return (
        <Box sx={classes.muiUpperComponents}>
            <Box component={Button}
                sx={classes.muiButton}
                onClick={handleOpen}>
                <Typography sx={classes.muiButtonLabel} color='secondary'>Create New Folder</Typography>
                <CreateNewFolderIcon sx={{
                    fontSize: '3rem',
                    verticalAlign: 'middle'
                }} color='secondary' />
            </Box>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={classes.muiModalComponent}>
                        <TextField label="Category" variant="outlined" sx={{ margin: 1 }} autoComplete='off' value={category} onChange={e => setCategory(e.target.value)} />
                        <TextField
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            sx={{ margin: 1 }}
                            id="outlined-multiline-static"
                            label="Description"
                            autoComplete='off'
                            multiline
                            rows={4}
                        />
                        <Button variant='contained' sx={{ width: '50%', alignSelf: 'center', height: 50, margin: 1, textTransform: 'none' }} onClick={onCreate}>
                            <Typography variant='h6' sx={{ fontFamily: 'Varela Round' }}>Create</Typography>
                        </Button>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    )
}
