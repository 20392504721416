import React, { useState } from 'react';
import { Card, Stepper, Typography, Box, IconButton, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const classes = {
    muiBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: '100%',
        flexDirection: 'column',
    },
    muiContainer: {
        backgroundColor: (theme) => theme.palette.text.disabled,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minWidth: { lg: '40%', xs: '90%' },
        minHeight: { lg: '50%', xs: '65%' },
    },
    muiHelpers: {
        width: { lg: '50%', xs: '96%' },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 1
    },
    muiCard: {
        backgroundColor: '#1F7750',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: { lg: '40vw', xs: '86vw' },
        height: { lg: '50vh', xs: '50vh' },
        padding: 1,
        position: 'relative'
    },
}


export default function OnTest({ getPostID }) {
    const [count, setCount] = useState(1);
    //DATA
    const [activeStep, setActiveStep] = useState(0);
    // const [maxSteps, setmaxSteps] = useState()
    let maxSteps = 3
    //card-Actions
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    console.log(getPostID)
    return (
        <Stepper activeStep={maxSteps} alternativeLabel>
            <Box sx={classes.muiBox}>
                <Card sx={classes.muiCard}>
                    <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, fontFamily: 'Varela Round', margin: 1, }}>{count}</Typography>
                    <Typography sx={{ fontSize: { lg: '1.8rem', xs: '1.2rem' }, fontFamily: 'Varela Round', textAlign: 'center', marginTop: 2 }}>Definition</Typography>
                    <Box sx={{ position: 'absolute', bottom: 0, display: 'flex', flexDirection: 'column', width: '96%', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Button variant='contained' color='primary' sx={{ width: '40%', textTransform: 'none', textAlign: 'left' }}>A. Camera</Button>
                            <Button variant='contained' color='secondary' sx={{ width: '40%', textTransform: 'none', textAlign: 'left' }}>B. Lens</Button>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', m: 1 }}>
                            <Button variant='contained' color='secondary' sx={{ width: '40%', textTransform: 'none', textAlign: 'left' }}>C. Image</Button>
                            <Button variant='contained' color='primary' sx={{ width: '40%', textTransform: 'none', textAlign: 'left' }}>D. Sample</Button>
                        </Box>
                    </Box>

                </Card>
                <Box sx={classes.muiHelpers}>
                    <IconButton disabled={activeStep === 0} size='medium' onClick={handleBack}>
                        <ArrowBackIcon fontSize='large' />
                    </IconButton>

                    <Box component='span' sx={{ flexGrow: .2 }} />

                    <IconButton disabled={activeStep === maxSteps - 1} size='medium' onClick={handleNext}>
                        <ArrowForwardIcon fontSize='large' />
                    </IconButton>
                </Box>
            </Box>
        </Stepper>

    )
}
