//hooks
import React, { useState } from 'react'
//.mui
import {
    Typography,
    FormControl,
    Box, Divider
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


import ModeSwitch from '../headerComp/ModeSwitch';

const classes = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: { lg: '40vw', xs: '60vw' },
        height: '100%'
    },
    myTitle: {
        fontSize: '1.5rem',
        fontFamily: 'Varela Round',
        fontWeight: 'bold',
    },
    h1: {
        fontSize: "1rem",
        fontWeight: 700,
        color: (theme) => theme.palette.secondary.main,
        fontFamily: "Varela Round",
    },
    h3: {
        fontSize: "1rem",
        fontFamily: "Varela Round",
        color: "#3d84d1",
    },
    myTextField: {
        margin: 1
    }
}

export default function AppSettings() {
    const [language, setLanguage] = useState(10);
    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
    }
    return (
        <Box >

            <Box sx={classes.root}>
                <Typography sx={classes.myTitle}>Site Settings</Typography>
                <Divider style={{ width: '100%', marginBottom: 2 }} />
                <FormControl sx={{ marginBottom: 5 }}>
                    <Typography sx={classes.h1}>Select your Language</Typography>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={language}
                        onChange={handleChangeLanguage}
                        sx={{ width: 200 }}
                    >
                        <MenuItem value={10}><em>English (USA)</em></MenuItem>
                        <MenuItem value={20}>English (UK)</MenuItem>
                        <MenuItem value={30}>Filipino</MenuItem>
                        <MenuItem value={40}>Visaya</MenuItem>
                        <MenuItem value={50}>Chinese</MenuItem>
                    </Select>
                </FormControl>
                <Box sx={{ marginBottom: 5 }}>
                    <Typography align="justify" sx={classes.h1}>Change your theme</Typography>
                    <ModeSwitch />
                </Box>
            </Box>
        </Box>
    )
}
