import React, { useState, useEffect } from 'react';
import { Card, Stepper, Typography, Box, IconButton, Divider, } from '@mui/material';
import ReactCardFlip from 'react-card-flip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { auth, db } from '../../assets/utils/firebase';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
const classes = {
    muiBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: '100%',
        flexDirection: 'column',
    },
    muiContainer: {
        backgroundColor: (theme) => theme.palette.text.disabled,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minWidth: { lg: '40%', xs: '90%' },
        minHeight: { lg: '50%', xs: '65%' },
    },
    muiHelpers: {
        width: { lg: '50%', xs: '96%' },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 1
    },
    muiCard: {
        backgroundColor: '#1F7750',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: { lg: '40vw', xs: '86vw' },
        height: { lg: '50vh', xs: '50vh' },
        padding: 1,
        position: 'relative'
    },
}


export default function OnReview({ getPostID }) {
    //DATA
    const [count, setCount] = useState(1);
    const [activeStep, setActiveStep] = useState(0);
    const [maxSteps, setmaxSteps] = useState()
    const [cards, setCards] = useState([])

    //card-Actions
    const [isFlipped, setIsFlipped] = useState(false)
    const handleClick = () => {
        setIsFlipped(!isFlipped)
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCount(count + 1);
        setIsFlipped(false);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setCount(count - 1);
        setIsFlipped(false)
    };

    useEffect(() => {
        const fetchData = async () => {
            const userCards = collection(db, 'posts', getPostID, 'postCards')
            onSnapshot(userCards, (snapshot) => {
                setCards(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
                setmaxSteps(snapshot.size)
            })
        }
        console.log(cards)
        fetchData();
    }, [cards, getPostID])
    const data = cards[activeStep]
    return (
        <Stepper activeStep={maxSteps} alternativeLabel>
            <Box sx={classes.muiBox}>
                <Card sx={classes.muiContainer} >
                    <ReactCardFlip
                        isFlipped={isFlipped}
                        flipDirection='vertical'
                        flipSpeedFrontToBack={0.4}
                        flipSpeedBackToFront={0.4}
                    >
                        <Card sx={classes.muiCard} onClick={handleClick}>
                            <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, fontFamily: 'Varela Round', margin: 1, }}>{count}</Typography>
                            <Divider style={{ width: '100%', backgroundColor: '#EC884D', height: 2 }} />
                            <Typography sx={{ fontSize: { lg: '1.8rem', xs: '1.2rem' }, fontFamily: 'Varela Round', textAlign: 'center' }} color='secondary'>
                                Definition
                            </Typography>
                            <Divider style={{ width: '100%', marginBottom: 2, backgroundColor: '#EC884D', height: 2 }} />
                            <Typography sx={{
                                fontSize: { lg: '1.8rem', xs: '1.5rem' },
                                fontFamily: 'Varela Round',
                                textAlign: 'center',
                                marginTop: 10
                            }} color='text.primary'>
                                {data.definition}
                            </Typography>
                        </Card>

                        <Card sx={classes.muiCard} onClick={handleClick}>
                            <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, fontFamily: 'Varela Round', margin: 1, }}>{count}</Typography>
                            <Divider style={{ width: '100%', backgroundColor: '#639BC8', height: 2 }} />
                            <Typography sx={{ fontSize: { lg: '1.8rem', xs: '1.2rem' }, fontFamily: 'Varela Round', textAlign: 'center' }} color='primary'>
                                Term
                            </Typography>
                            <Divider style={{ width: '100%', marginBottom: 2, backgroundColor: '#639BC8', height: 2 }} />
                            <Typography
                                sx={{
                                    fontSize: { lg: '1.8rem', xs: '1.5rem' },
                                    fontFamily: 'Varela Round',
                                    textAlign: 'center',
                                    marginTop: 10
                                }} color='text.primary'>
                                {data.term}
                            </Typography>
                        </Card>
                    </ReactCardFlip>
                    <Box sx={classes.muiHelpers}>
                        <IconButton disabled={activeStep === 0} size='medium' onClick={handleBack}>
                            <ArrowBackIcon fontSize='large' />
                        </IconButton>

                        <Box component='span' sx={{ flexGrow: .2 }} />

                        <IconButton disabled={activeStep === maxSteps - 1} size='medium' onClick={handleNext}>
                            <ArrowForwardIcon fontSize='large' />
                        </IconButton>
                    </Box>
                </Card>

            </Box>
        </Stepper>
    )
}
