//hooks
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//JSX.ELEMENTS
import { useNavigate } from 'react-router-dom';
import SideDrawer from './headerComp/Drawer';
import ModeSwitch from './headerComp/ModeSwitch';
//redux
import { getUserInfo, setLoggedOutState } from '../redux/actions/authAction';
//.mui
import { styled, alpha } from '@mui/material/styles';
import {
  AppBar, Box, Toolbar, IconButton,
  InputBase, MenuItem, Menu, Avatar, Typography
} from '@mui/material';
//ICONS
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: 'auto',
  borderRadius: `25px 25px 25px 25px`,
  [theme.breakpoints.down('sm')]: {
    width: '92%',
    display: 'inline-block',
    position: 'absolute',
    top: 70
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

export default function Header() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate()
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickLogOut = () => {
    handleMenuClose()
    dispatch(setLoggedOutState())
  }
  const user = useSelector(state => state.user)
  useEffect(() => {
    dispatch(getUserInfo())
  }, [dispatch])
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      placement="bottom-start"
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { navigate(`/profile/${user.currentUser.displayName}/${user.currentUser.id}`) }}>
        {user.currentUser.photoURL === "" ? (< AccountCircle />) : (<Avatar sx={{ width: 32, height: 32, marginRight: 1 }} src={user.currentUser.photoURL} alt={user.currentUser.displayName} />)} Profile

      </MenuItem>
      <MenuItem>
        <ModeSwitch />
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => { navigate('/about') }}>
        <ListItemIcon>
          <InfoIcon fontSize="small" />
        </ListItemIcon>
        About
      </MenuItem>
      <MenuItem onClick={() => { navigate('/settings/edit-user-profile') }}>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        Settings
      </MenuItem>
      <MenuItem onClick={handleClickLogOut}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" enableColorOnDark sx={{ minHeight: { lg: 'auto', sm: 'auto', xs: 120 } }}>
        <Toolbar>
          <SideDrawer />
          <Typography sx={{ fontSize: { lg: 36, md: 32, sm: 28, xs: 24 }, fontWeight: "800", fontFamily: "Varela Round", }}>
            Reviewees
          </Typography>

          <Box sx={{ flexGrow: .2 }} />

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          <Box sx={{ flexGrow: 10 }} />
          <Box sx={{ display: 'flex' }}>
            <IconButton
              size="large"
              color="inherit"
              onClick={() => { navigate('/home') }}
            >
              <HomeIcon />
            </IconButton>
            <IconButton
              size="large"
              color="inherit"
              onClick={() => { navigate('/messages') }}
            >
              <GroupsIcon />
            </IconButton>
            <IconButton
              size="large"
              color="inherit"
              onClick={() => { navigate('/folders') }}>
              <FolderIcon />
            </IconButton>

            <Tooltip title="Account settings">
              <IconButton
                onClick={handleProfileMenuOpen}
                aria-controls={isMenuOpen ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? 'true' : undefined}
                color="inherit"
                sx={{ display: { xs: 'none', lg: 'block' } }}
              >
                {user.currentUser.photoURL === "" ? (< AccountCircle />) : (<Avatar sx={{ width: 24, height: 24 }} src={user.currentUser.photoURL} />)}
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>

        
      </AppBar>
      {renderMenu}
    </Box >
  );
}