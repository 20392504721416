//hooks
import React, { useState, useEffect } from 'react'
//JSX.ELEMETS
import { useDispatch, useSelector } from 'react-redux';
//.mui
import {
    Grid, TextField, Typography, OutlinedInput,
    InputAdornment, IconButton, FormControl, InputLabel,
    Button, Box, useMediaQuery, FormLabel, RadioGroup,
    FormControlLabel, Radio, Alert
} from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MobileDatePicker, LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ImGoogle2, ImFacebook2 } from 'react-icons/im'
import { TiVendorMicrosoft } from 'react-icons/ti'
import { createNewUserEmail, setLoggedInUserFB, setLoggedInUserGoogle, setLoggedInUserMicrosoft } from '../redux/actions/authAction';

const classes = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    myTextField: {
        width: { lg: 300, xs: 255 },
        margin: 1,
    },
    myTitle: {
        fontSize: '3rem',
        fontFamily: 'Varela Round',
        fontWeight: 'bold'
    },
    myText: {
        marginBottom: 1,
        fontSize: '1rem',
        opacity: '0.7',
        textAlign: 'center',
        fontFamily: 'Varela Round',
    },
    myLabels: {
        fontSize: '1rem',
        paddingLeft: 1,
        textAlign: 'center',
        fontFamily: 'Varela Round',
    },
}

export default function Register() {
    const matchXS = useMediaQuery('(min-width:600px)');
    const dispatch = useDispatch()
    const [birthDate, setBirthDate] = useState(new Date('2014-08-18'));
    const error = useSelector(state => state.user.error)
    const [payload, setPayload] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPass: '',
        gender: '',
        photoURL: '',
        showPassword: false,
        showCPassword: false,
    });
    const [errors, setErrors] = useState('')

    const handleDate = (newValue) => {
        setBirthDate(newValue);
    };
    const handleChange = (prop) => (event) => {
        setPayload({ ...payload, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setPayload({ ...payload, showPassword: !payload.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowCPassword = () => {
        setPayload({ ...payload, showCPassword: !payload.showCPassword });
    };
    const handleMouseDownCPassword = (event) => {
        event.preventDefault();
    };
    const handleFBLogin = (e) => {
        dispatch(setLoggedInUserFB())
    }
    const handleGoogleLogin = (e) => {
        dispatch(setLoggedInUserGoogle())
    };
    const handleMSLogin = (e) => {
        dispatch(setLoggedInUserMicrosoft())
    }
    const handleSignUp = () => {
        if (payload.password !== payload.confirmPass) {
            setErrors("Passwords do not match.")
            if (!payload.email || !payload.password || !payload.firstName || !payload.lastName || !birthDate || !payload.gender) {
                setErrors("Please fill out all fields!")
            }
            else { setErrors("") }
        }
        else {
            dispatch(createNewUserEmail(payload.email, payload.password, payload.firstName, payload.lastName, birthDate.toLocaleDateString(), payload.gender, payload.photoURL))
        }
    }
    useEffect(() => {
        setErrors(error)
    }, [error])
    return (
        <Grid container sx={classes.container}>
            {errors && (< Alert severity="error">
                {"Error - " + errors}
            </Alert>)}
            <Typography sx={classes.myTitle}>Sign Up</Typography>
            <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                <IconButton color='inherit' sx={{ m: 1, textTransform: 'none' }} onClick={handleFBLogin}>
                    <ImFacebook2 style={{ color: '#5375e2' }} />
                </IconButton>
                <IconButton color='inherit' sx={{ m: 1, textTransform: 'none' }} onClick={handleGoogleLogin}>
                    <ImGoogle2 style={{ color: '#5375e2' }} />
                </IconButton>
                <IconButton color='inherit' sx={{ m: 1, textTransform: 'none' }} onClick={handleMSLogin}>
                    <TiVendorMicrosoft style={{ color: '#5375e2' }} />
                </IconButton>
            </Grid>
            <Typography sx={classes.myText}>or create an account</Typography>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <TextField
                    required
                    inputProps={{ sx: { textTransform: 'capitalize' } }}
                    label="First Name"
                    variant="outlined"
                    size='small'
                    sx={{ width: 142, marginRight: 1 }}
                    color='secondary'
                    value={payload.firstName}
                    onChange={handleChange('firstName')}
                />
                <TextField
                    required
                    inputProps={{ sx: { textTransform: 'capitalize' } }}
                    label="Last Name"
                    variant="outlined"
                    size='small'
                    sx={{ width: 142, marginLeft: 1 }}
                    color='secondary'
                    value={payload.lastName}
                    onChange={handleChange('lastName')}
                />
            </Box>
            <TextField
                required
                sx={classes.myTextField}
                label="Email"
                variant="outlined"
                size='small'
                color='secondary'
                value={payload.email}
                onChange={handleChange('email')}
            />

            <FormControl required sx={classes.myTextField} variant="outlined" size='small' color='secondary' >
                <InputLabel htmlFor="outlined-adornment-Rpassword">Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-Rpassword"
                    type={payload.showPassword ? 'text' : 'password'}
                    value={payload.password}
                    onChange={handleChange('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {payload.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
            <FormControl required sx={classes.myTextField} variant="outlined" size='small' color='secondary'>
                <InputLabel htmlFor="outlined-adornment-Cpassword">Confirm Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-Cpassword"
                    type={payload.showCPassword ? 'text' : 'password'}
                    value={payload.confirmPass}
                    onChange={handleChange('confirmPass')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowCPassword}
                                onMouseDown={handleMouseDownCPassword}
                                edge="end"
                            >
                                {payload.showCPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Confirm Password"
                />
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {!matchXS ?
                    <MobileDatePicker
                        label="Birthdate"
                        inputFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        minDate={new Date('1900-01-01')}
                        value={birthDate}
                        onChange={handleDate}
                        renderInput={(params) => <TextField {...params} size='small' color='secondary' sx={classes.myTextField} />}
                    />
                    :
                    <DesktopDatePicker
                        label="Birthdate"
                        inputFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        minDate={new Date('1900-01-01')}
                        value={birthDate}
                        onChange={handleDate}
                        renderInput={(params) => <TextField {...params} size='small' color='secondary' sx={classes.myTextField} />}
                    />
                }
            </LocalizationProvider>

            <FormControl required component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    sx={{ width: 300 }}
                    value={payload.gender}
                    onChange={handleChange('gender')}>
                    <FormControlLabel value="male" control={<Radio color='secondary' />} label="Male" />
                    <FormControlLabel value="female" control={<Radio color='secondary' />} label="Female" sx={{ marginLeft: 13 }} />
                </RadioGroup>
            </FormControl>

            <Button variant="contained" color='primary' sx={{ textTransform: 'none' }} onClick={handleSignUp}>
                <Typography sx={{ fontFamily: 'Varela Round', fontSize: '1rem' }}>Sign Up</Typography>
            </Button>
        </Grid>
    )
}
