//hooks
import React, { useEffect, useRef, useState } from 'react'
//.mui
import TextInput from './TextInput'
import { Avatar, Tooltip, Typography, Box, CircularProgress } from '@mui/material';
import { db } from '../../assets/utils/firebase';
import { useSelector } from 'react-redux';
import { collection, query, orderBy, onSnapshot, } from 'firebase/firestore';

const classes = {
    sent: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
    },
    received: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    sentText: {
        border: 1,
        maxWidth: 300,
        display: 'inline-block',
        margin: '15px 0px 0px 0px',
        flexWrap: 'wrap',
        borderRadius: 3,
        padding: 1,
        backgroundColor: '#639BC8'
    },
    receivedText: {
        border: 1,
        maxWidth: 300,
        display: 'inline-block',
        margin: '15px 0px 0px 0px',
        flexWrap: 'wrap',
        borderRadius: 3,
        padding: 1,
        backgroundColor: '#1F7750',
    },
}

export default function ChatBox() {
    const smsRef = query(collection(db, 'messages'), orderBy('createdAt'));
    const user = useSelector(state => state.user);
    const scroll = useRef();
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        async function displayMsg() {
            onSnapshot(smsRef, (snapshot) => {
                setMessages(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            })
        }
        displayMsg()
        setIsLoading(false)
    }, [smsRef]);
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', backgroundColor: (theme) => theme.palette.background.default, }}>
                <CircularProgress size='30vw' thickness={3} />
            </Box>
        );
    }
    return (
        <Box sx={{
            width: '100%',
            maxHeight: '95%',
            position: 'inherit',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box
                sx={{
                    flexDirection: 'column',
                    maxHeight: { lg: '74vh', xs: '72vh' },
                    minHeight: { lg: '74vh', xs: '72vh' },
                    display: 'flex',
                    padding: '5px 0px 10px 0px',
                    overflowY: 'scroll',
                }}>
                {messages.map(({ id, text, senderphotoURL, senderID, createdAt }) => (
                    <Box key={id} sx={senderID === user.currentUser.id ? classes.sent : classes.received}>
                        <Avatar src={senderphotoURL} sx={{
                            margin: '15px 10px 0px 15px',
                            width: 32,
                            height: 32,
                            border: '3px solid'
                        }} />
                        <Tooltip title={'sent ' + createdAt}>
                            <Box sx={senderID === user.currentUser.id ? classes.sentText : classes.receivedText}>
                                <Typography sx={{ fontFamily: 'Varela Round', }}>
                                    {text}
                                </Typography>
                            </Box>
                        </Tooltip>
                    </Box>
                ))}

            </Box>
            <div ref={scroll} />
            <TextInput scroll={scroll} />
        </Box >
    )
}
