//hooks
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux';
//JSX.ELEMENTS
import Home from '../pages/Home'
import Login from '../components/Login'
import WelcomePage from '../pages/WelcomePage';
import Register from '../components/Register';
import About from '../pages/About';
import Folders from '../pages/Folders'
import Profile from '../pages/Profile';
import CreateSets from '../components/modalbox/CreateSets';
import Decks from '../pages/Decks';
import EditSets from '../components/modalbox/EditSets';
import Settings from '../pages/Settings';
import Messages from '../pages/Messages';
import ChatBox from '../components/messanging/ChatBox';
import UserInfoUpdate from '../components/settings/UserInfoUpdate';
import Privacy from '../components/settings/Privacy';
import AppSettings from '../components/settings/AppSettings';
import DisplayReview from '../components/modalbox/DisplayReview';
import DisplayTest from '../components/modalbox/DisplayTest';
import PasswordUpdate from '../components/settings/PasswordUpdate';
//.mui


export default function RouterComponent() {
    const user = useSelector(state => state.user.isAuthenticated)
    return (
        <Routes>
            <Route path="/" element={<Navigate to={"/landpage/login"} />} />
            {!user && (
                <>
                    <Route path="/landpage" element={<WelcomePage />}>
                        <Route path="login" element={<Login />} />
                        <Route path="registration" element={<Register />} />
                    </Route>
                </>
            )
            }
            {user && (
                <>
                    <Route path="/home" element={<Home />} />
                    <Route path="/messages" element={<Messages />} >
                        <Route path="chats/:id" element={<ChatBox />} />
                    </Route>
                    <Route path="/profile/:name/:id" element={<Profile />} />
                    <Route path="/folders" element={<Folders />} />
                    <Route path="/settings" element={<Settings />} >
                        <Route path="edit-user-profile" element={<UserInfoUpdate />} />
                        <Route path="change-password" element={<PasswordUpdate />} />
                        <Route path="edit-app-settings" element={<AppSettings />} />
                        <Route path="privacy" element={<Privacy />} />
                    </Route>
                    <Route path="/folders/:folderId/decks" element={<Decks />} />
                    <Route path="/folders/:folderId/decks/review/:setId" element={<DisplayReview />} />
                    <Route path="/folders/:folderId/decks/test/:setId" element={<DisplayTest />} />
                    <Route path="/folders/:folderId/decks/create" element={<CreateSets />} />
                    <Route path="/folders/:folderId/decks/edit/:setId" element={<EditSets />} />
                </>
            )
            }
            <Route path="about" element={<About />} />
            <Route path="*" element={<Navigate to={user ? "/home" : "/landpage/login"} />} />
        </Routes >
    );
};
