//hooks
import React from 'react'
//JSX.ELEMETS
//.mui
import {
    Box, Typography
} from '@mui/material'
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { MobileDatePicker, LocalizationProvider, DesktopDatePicker } from '@mui/lab';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';

const classes = {
    root: {
        width: "100%",
        maxWidth: { lg: '40vw', xs: '60vw' },
        maxHeight: '50vh',
        whiteSpace: 'wrap',
        overflowY: 'auto',
        
    },
    h1: {
        fontSize: { lg: "1.5rem", xs: "1rem" },
        fontWeight: 700,
        color: (theme) => theme.palette.secondary.main,
        fontFamily: "Segoe UI Emoji",
        textAlign: 'center'
    },
    h2: {
        fontSize: { lg: "1rem", xs: "0.8rem" },
        color: (theme) => theme.palette.secondary.main,
        fontFamily: "Segoe UI Symbol",
        textAlign: 'center'
    },
    h3: {
        fontSize: { lg: "1rem", xs: "0.8rem" },
        fontFamily: "Segoe UI Symbol",
        color: "#3d84d1",
        textAlign: 'center'

    }

}

export default function Privacy() {


    return (
        <Box container>
            <Box sx={classes.root} >
                <Typography sx={classes.h3}>Effective date: December 1, 2021;</Typography>
                <Typography sx={classes.h1}>Reviewees Privacy and Policy</Typography>
                <Typography sx={{ fontSize: { lg: "1rem", xs: "0.8rem" }, textAlign: 'center' }} gutterBottom>

                    Reviewees app is dedicated to helping anyone to practice and master whatever they are learning.
                    Anyone can share and create study sets to learn online, study with friends and share your knowledge to anyone.
                    When you share information with us we can help you achieve that goal by helping you find the right content
                    and making your studying quicker and more effective.

                </Typography>
                <Typography sx={classes.h1}>Information you Provide to Us to Use the Service</Typography>
                <Typography sx={{ fontSize: { lg: "1rem", xs: "0.8rem" }, textAlign: 'center' }} gutterBottom>

                    Account information. We collect the information you provide when you sign up for a Quizlet account,
                    log in to your account, communicate with us, answer our surveys, upload content, or otherwise use the Services.

                </Typography>
                <Typography sx={classes.h1}>Information You Provide to Enhance Your Experience</Typography>
                <Typography sx={{ fontSize: { lg: "1rem", xs: "0.8rem" }, textAlign: 'center' }} gutterBottom>
                    You can choose to provide us with additional information in order to obtain a better user experience when using the Service.
                    This additional information will be processed with your consent and/or to provide you with services you request.
                    This information includes your survey responses, participation in contests, promotions or other marketing efforts,
                    suggestions for improvements, referrals, or any other actions on the Service.
                </Typography>
            </Box>
        </Box>
    )
}
